import { Box, Stack } from '@mantine/core';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import React from 'react';
import { Detail, SectionCollapse } from '../elements';
import { useAssetEditPanel } from '../context';
import { ContentProps } from '../../../elements/ManageView';
import { AssetIcon } from '@pixi/components/AssetThumbnail';
import { getMultiStore } from '@pixi/store';
import useContextFromFile from 'hooks/useContextFromFile';
import PixiConfirm from '@pixi/elements/Confirm';
import { createAppToast } from '@pixi/AppController';

interface TrashViewProps {
  onHide: ((isForceHidden?: boolean) => void) | undefined;
}

export default function TrashView({ onHide }: TrashViewProps) {
  const { file, files } = useAssetEditPanel();

  const context = useContextFromFile(file); // What to use here?... Check w. Jacob

  const allFiles: Pickit.FileInterface[] = files.length > 1 ? files : [file];

  const multipleSelected = files.length > 1;

  async function recoverFiles() {
    await context.recoverDocumentsFromTrash(allFiles);
  }

  async function deleteFiles() {
    await context.deleteFiles(allFiles.map((file) => file._id));
  }

  return (
    <Box h="100%" style={{ overflow: 'auto' }} bg="white">
      <SectionCollapse
        label={
          files?.length > 1 ? (
            <>{files.length} files selected</>
          ) : (
            <>1 file selected</>
          )
        }
        noChevron
        noDivider
        icon="circle-info"
        rightSection={
          <PixiButton
            size="xs"
            px="xs"
            color="dark"
            variant="subtle"
            style={{ flexShrink: 0 }}
            onClick={() => {
              onHide?.();
            }}
          >
            <PixiIcon name="arrow-right-from-line" />
          </PixiButton>
        }
      >
        {allFiles.map((file) => (
          <Detail
            key={file._id}
            icon={<AssetIcon file={file} />}
            left={file.name}
            right={
              <PixiButton
                color="gray"
                variant="light"
                size="xs"
                px="xs"
                onClick={() => {
                  getMultiStore('FILES_SELECTED', 'manageLibrary').remove(file);
                }}
              >
                <PixiIcon name="xmark" />
              </PixiButton>
            }
          />
        ))}
      </SectionCollapse>
      <Stack
        h="100%"
        gap="10"
        p="lg"
        style={{ overflow: 'auto', display: 'flex', justifyContent: 'start' }}
      >
        <PixiButton
          size="wide-md"
          color="dark"
          variant="glass"
          loading={false}
          disabled={false}
          style={{
            flexShrink: 1,
            marginRight: 0,
          }}
          leftSection={
            <PixiIcon name="rotate-reverse" size="lg" variant="filled" />
          }
          onClick={() => {
            recoverFiles();
            onHide?.();
            createAppToast({
              id: `deleted_file${file._id}`,
              message: `${multipleSelected ? 'Files were recovered successfully' : 'File was recovered successfully'}`,
              icon: 'trash-can',
            });
          }}
        >
          Recover {multipleSelected && 'selected files'}
        </PixiButton>

        <PixiConfirm
          title="Are you sure?"
          description="The selected files will be deleted permanently. This can't be undone."
          confirmLabel="Delete files"
          confirmProps={{
            color: 'red',
          }}
          onConfirm={async () => {
            deleteFiles();
            onHide?.();
            createAppToast({
              id: `deleted_file${multipleSelected ? allFiles.map((file) => file._id).join('&') : file._id}`, // App toast id?
              message: `${multipleSelected ? 'Files were deleted successfully' : 'File was deleted successfully'}`,
              icon: 'trash-can',
            });
          }}
        >
          <PixiButton
            size="wide-md"
            color="red"
            variant="filled"
            loading={false}
            disabled={false}
            style={{
              flexShrink: 1,
              marginRight: 0,
            }}
            leftSection={
              <PixiIcon name="trash-can" size="lg" variant="filled" />
            }
          >
            Delete permanently
          </PixiButton>
        </PixiConfirm>
      </Stack>
    </Box>
  );
}
