import {
  Alert,
  Anchor,
  Avatar,
  Badge,
  Box,
  Card,
  Collapse,
  ColorSwatch,
  Divider,
  Group,
  GroupProps,
  Image,
  Input,
  JsonInput,
  Loader,
  NavLink,
  Progress,
  Stack,
  TextInput,
  Textarea,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import FeedbackForm from '@pixi/components/AssetQuickForms/FeedbackForm';
import VariantForm from '@pixi/components/AssetQuickForms/VariantForm';
import PixiButton from '@pixi/elements/Button';
import PixiConfirm from '@pixi/elements/Confirm';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import { formatDuration } from '@pixi/helpers/utils';
import { DjangoIdToName, useDjangoIdToEmail } from 'components/DjangoIdToName';
import { format, formatDistanceToNow } from 'date-fns';
import { useUserContext } from 'hooks';
import { useEffect, useState } from 'react';
import { useAssetEditPanel } from '@pixi/components/AssetPanel/context';
import { SectionCollapse } from '@pixi/components/AssetPanel/elements';
import useUserStatus from 'hooks/useUserStatus';

export default function AssetEditPanelApprovals() {
  const { file, files, context, onClose, focus } = useAssetEditPanel();
  const User = useUserContext();
  const [approvalComment, setApprovalComment] = useState<string>(
    file?.approval?.message || '',
  );
  const userStatus = useUserStatus();
  useEffect(() => {
    setApprovalComment(file.approval?.message || '');
  }, [file?.approval?.message]);

  if (!userStatus.activeServices?.approval) {
    return <></>;
  }

  return (
    <SectionCollapse
      label={<>Approvals</>}
      defaultOpen={
        focus === 'approvals' ||
        (file?.approval?.status &&
          file?.approval?.status !== 'approved' &&
          file?.approval?.status !== 'rejected')
      }
      isFocused={focus === 'approvals'}
    >
      <Stack>
        <Card withBorder radius="sm">
          <Stack gap="xs">
            <Group justify="space-between">
              <PixiText size="sm" fw="500">
                {file.approval?.status === 'approved'
                  ? 'Approved'
                  : file.approval?.status === 'rejected'
                    ? 'Rejected'
                    : 'Approval needed'}
              </PixiText>
              {file.approval?.status !== 'none' &&
                file.approval?.status !== 'approval-needed' && (
                  <PixiDropdown
                    target={
                      <PixiButton
                        size="xs"
                        px="xs"
                        color="dark"
                        variant="subtle"
                        style={{ flexShrink: 0 }}
                      >
                        <PixiIcon name="ellipsis" />
                      </PixiButton>
                    }
                  >
                    <PixiDropdown.Item
                      onClick={async () => {
                        if (files?.length) {
                          await context.saveDocumentsWhere(
                            {
                              _id: { $in: files.map((f) => f._id) },
                            },
                            {
                              'approval.status': 'none',
                              'approval.status_changed_by':
                                User.djangoProfile.id,
                              'approval.status_changed_at': new Date(),
                            },
                          );
                          return;
                        }
                        await context.savePartial({
                          _id: file._id,
                          'approval.status': 'none',
                          'approval.status_changed_by': User.djangoProfile.id,
                          'approval.status_changed_at': new Date(),
                        });
                      }}
                    >
                      Reset status
                    </PixiDropdown.Item>
                  </PixiDropdown>
                )}
            </Group>
            <Textarea
              size="sm"
              value={approvalComment}
              placeholder="Comment"
              key={file?._id}
              onChange={(event) => {
                setApprovalComment(event.currentTarget.value);
              }}
            />
            <Group w="100%" justify="space-between">
              <PixiText size="xs" c="dimmed">
                <DjangoIdToName value={file.approval?.status_changed_by} />{' '}
              </PixiText>
              {file.approval?.status_changed_at && (
                <PixiText size="xs" c="dimmed">
                  {formatDistanceToNow(
                    new Date(file.approval?.status_changed_at),
                  )}{' '}
                  ago
                </PixiText>
              )}
            </Group>
            <Group wrap={files?.length ? 'wrap' : 'nowrap'} gap="5">
              <PixiButton
                fullWidth
                size="xs"
                onClick={async () => {
                  if (files?.length) {
                    await context.saveDocumentsWhere(
                      {
                        _id: { $in: files.map((f) => f._id) },
                      },
                      {
                        'approval.status': 'rejected',
                        'approval.status_changed_by': User.djangoProfile.id,
                        'approval.status_changed_at': new Date(),
                        'approval.message': approvalComment,
                      },
                    );
                    return;
                  }
                  await context.savePartial({
                    _id: file._id,
                    'approval.status': 'rejected',
                    'approval.status_changed_by': User.djangoProfile.id,
                    'approval.status_changed_at': new Date(),
                    'approval.message': approvalComment,
                  });
                  onClose?.();
                }}
                variant="light"
                leftSection={<PixiIcon name="xmark" size="xs" />}
                disabled={
                  !files?.length && file.approval?.status === 'rejected'
                }
              >
                {files?.length ? 'Mark as rejected' : 'Reject'}
              </PixiButton>
              <PixiButton
                fullWidth
                onClick={async () => {
                  if (files?.length) {
                    await context.saveDocumentsWhere(
                      {
                        _id: { $in: files.map((f) => f._id) },
                      },
                      {
                        'approval.status': 'approved',
                        'approval.status_changed_by': User.djangoProfile.id,
                        'approval.status_changed_at': new Date(),
                        'approval.message': approvalComment,
                        share_to: 'all',
                      },
                    );
                    return;
                  }
                  await context.savePartial({
                    _id: file._id,
                    'approval.status': 'approved',
                    'approval.status_changed_by': User.djangoProfile.id,
                    'approval.status_changed_at': new Date(),
                    'approval.message': approvalComment,
                    share_to: 'all',
                  });
                  onClose?.();
                }}
                size="xs"
                variant={files?.length ? 'light' : 'filled'}
                leftSection={<PixiIcon name="check" size="xs" />}
                disabled={
                  !files?.length && file.approval?.status === 'approved'
                }
              >
                {files?.length ? 'Mark as approved' : 'Approve'}
              </PixiButton>
            </Group>
          </Stack>
        </Card>
      </Stack>
    </SectionCollapse>
  );
}
