import { Group, Indicator, MenuItemProps } from '@mantine/core';
import PixiDropdown from '@pixi/elements/Dropdown';
import { PixiIconName } from '@pixi/elements/Icon';
import PixiText from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import {
  differenceInHours,
  format,
  formatDistanceToNow,
  isValid,
} from 'date-fns';
import { useUserContext } from 'hooks';
import { ReactNode, useEffect, useState } from 'react';
import { copyTextToClipboard } from 'utils';
import localStorageWrapper from 'utils/localStorageWrapper';

export default function AppInformationDropdown() {
  const User = useUserContext();
  const [apiBuild, setApiBuild] = useState<{
    'pickit-compiled-ago'?: string;
    'pickit-compiled-date'?: string;
  }>({});
  async function getAPIBuild() {
    const data = await fetch(`${window.pickit.config.MEDIA_URL}/status`);
    const value = await data.json();
    setApiBuild(value);
  }

  useEffect(() => {
    getAPIBuild();
  }, []);

  function renderData(
    icon: PixiIconName,
    left: ReactNode,
    right: ReactNode,
    tooltip?: string,
    copyData?: string,
    props?: Partial<MenuItemProps>,
  ) {
    const target = (
      <PixiDropdown.Item
        icon={icon}
        rightSection={right}
        onClick={() => {
          if (copyData) {
            copyTextToClipboard(copyData);
          }
        }}
        {...props}
      >
        <PixiText size="sm">{left}</PixiText>
      </PixiDropdown.Item>
    );
    if (tooltip) {
      return (
        <PixiTooltip label={tooltip} position="right">
          {target}
        </PixiTooltip>
      );
    }
    return target;
  }

  return (
    <>
      <PixiDropdown.Label>Build</PixiDropdown.Label>
      {apiBuild['pickit-compiled-date'] &&
        isValid(apiBuild['pickit-compiled-date']) &&
        renderData(
          'server',
          'API',
          <Group align="center" gap="xs">
            {formatDistanceToNow(new Date(apiBuild['pickit-compiled-date']))}{' '}
            ago{' '}
            <Indicator
              mr="5"
              color={
                differenceInHours(
                  new Date(),
                  new Date(window.pickit.RELEASE_DATE),
                ) > 24
                  ? 'red'
                  : 'green'
              }
            />
          </Group>,
          format(new Date(apiBuild['pickit-compiled-date']), 'PPpp'),
          format(new Date(apiBuild['pickit-compiled-date']), 'PPpp'),
        )}
      {renderData(
        'browser',
        'Application',
        <Group align="center" gap="xs">
          {formatDistanceToNow(new Date(window.pickit.RELEASE_DATE))} ago{' '}
          <Indicator
            mr="5"
            color={
              differenceInHours(
                new Date(),
                new Date(window.pickit.RELEASE_DATE),
              ) > 24
                ? 'red'
                : 'green'
            }
          />
        </Group>,
        format(new Date(window.pickit.RELEASE_DATE), 'PPpp'),
        format(new Date(window.pickit.RELEASE_DATE), 'PPpp'),
      )}
      <PixiDropdown.Divider />
      <PixiDropdown.Label>Information</PixiDropdown.Label>
      {renderData(
        'user',
        'User',
        User?.djangoProfile?.id,
        '',
        User?.djangoProfile?.id,
      )}
      {renderData(
        'store',
        'Customer',
        User?.spaces?.selected?.slug,
        '',
        User?.spaces?.selected?.slug,
      )}
      <PixiDropdown.Divider />
      <PixiDropdown.Label>Tools</PixiDropdown.Label>
      <PixiDropdown.Item
        onClick={() => {
          localStorageWrapper.clear();
          window.location.reload();
        }}
        icon="arrow-rotate-right"
      >
        Clear cache and reload
      </PixiDropdown.Item>
    </>
  );
}
