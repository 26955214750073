import { differenceInHours } from 'date-fns';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { useUserContext } from 'hooks/useUserContext';
import useIntegrationHelpers from 'hooks/import/useIntegrationHelpers';
import { useEffect, useReducer, useState } from 'react';
import asyncPool from 'utils/async-pool';

const splitToChunks = (array, parts) => {
  const result = [];
  for (let i = parts; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
};

export default function useDropbox(props) {
  const { Dropbox } = props;
  const DAILY_SYNC_INTERVAL_HOURS = 4;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const IntegrationHelpers = useIntegrationHelpers();
  const User = useUserContext();
  const [syncJobs, setSyncJobs] = useState([]);
  const [forceJob, setForceJob] = useReducer(
    (state, action) => [...action],
    []
  );
  const [jobs, setJobs] = useReducer((state, action) => {
    if (Array.isArray(action)) {
      return [...action];
    }
    if (action?.type === 'UPDATE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? { ...j, status: { ...j.status, ...action.status } }
          : j
      );
    }
    if (action?.type === 'UPDATE_FILE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? {
              ...j,
              status: {
                ...j.status,
                files: {
                  ...(j.status?.files || {}),
                  [action.fileId]: action.status,
                },
              },
            }
          : j
      );
    }
  }, []);

  const [jobQueue, setJobQueue] = useReducer(
    (state, action) => [...action],
    []
  );
  const [dropboxCache, setDropboxCache] = useState(Date.now());
  const [dropboxFiles, setDropboxFiles] = useReducer((state, action) => {
    if (!action.data) return state;
    if (action.type === 'RESET') {
      return {
        [action.data.path]: action.data,
      };
    }
    if (action.type === 'SET') {
      return {
        ...state,
        [action.data.path]: action.data,
      };
    }
    if (action.type === 'PUSH') {
      const newData = state[action.data.path].data?.data
        ? state[action.data.path].data.data
        : state[action.data.path].data;
      const actionData =
        action?.data?.data?.data?.data ||
        action?.data?.data?.data ||
        action?.data?.data ||
        action?.data ||
        [];
      if (actionData.data?.path === newData.path);
      {
        return {
          ...state,
          [action.data.path]: {
            ...state[action.data.path],
            path: action.data.path,
            cursor: action.data.cursor,
            hasMore: action.data.hasMore,
            data: [...newData, ...actionData],
          },
        };
      }
    }
  }, {});

  async function getImportedFiles() {
    await Promise.all(
      ['media', 'documents'].map(async (type) => {
        if (type === 'media') {
          await Media.importGetFiles('dropbox');
        }
        if (type === 'documents') {
          await Documents.importGetFiles('dropbox');
        }
      })
    );
  }

  async function queueJob(job, force) {
    setJobQueue([...jobQueue, job._id]);

    if (force && !forceJob.includes(job._id)) {
      setForceJob([...forceJob, job._id]);
    }
  }

  async function getJobs() {
    const data = await User.request.files.importJobs.getJobs('dropbox');
    const jobs = await asyncPool(10, data.jobs, async (job) => {
      let metadata;
      return {
        ...job,
        metadata,
      };
    });
    setJobs(jobs);
    // setJobQueue(jobs.filter((job) => differenceInHours(new Date(), new Date(job.lastRun)) >= DAILY_SYNC_INTERVAL_HOURS).map((job) => job._id));
  }
  const getChunkedReferences = async (Library, from, allIds) => {
    let idChunks = [];
    let allRefs = [];
    const CHUNK_MAX_SIZE = 50;
    if (allIds.length < CHUNK_MAX_SIZE) {
      idChunks.push(allIds);
    } else {
      idChunks = splitToChunks(
        allIds,
        Math.round(allIds.length / CHUNK_MAX_SIZE)
      );
    }
    for (const ids of idChunks) {
      const refs = await Library.importCheckReferences(from, ids);
      allRefs = [...allRefs, ...refs];
    }
    return allRefs;
  };

  const libraries = {
    documents: useDocumentsContext(),
    media: useMediaContext(),
    brand: useMediaContext(),
  };

  // Fix this later
  function checkIfSupportedByAllLibraries(ext) {
    return Object.values(libraries).reduce((isOk, context) => {
      if (!isOk && context.isFileSupported(null, ext)) {
        isOk = true;
      }
      return isOk;
    }, false);
  }

  const processFiles = (files) =>
    files.map((file) => {
      if (file['.tag'] === 'folder') return { ...file, supported: true };
      if (
        checkIfSupportedByAllLibraries(
          file.name.split('.').pop()?.toLowerCase()
        ) &&
        !file.name.includes('.zip')
      )
        return { ...file, supported: true };
      return { ...file, supported: false };
    });

  const removeSyncJob = (id) =>
    setSyncJobs(syncJobs.filter((job) => job._id !== id));
  const searchWithParsedData = async (value, cursor) => {
    const result = await Dropbox.search(value, cursor);
    let parsedFiles = await processFiles(
      result.matches.map((match) => match.metadata.metadata)
    );
    parsedFiles = parsedFiles.map((file) =>
      IntegrationHelpers.createFileObject(
        file.path_lower,
        file.name,
        file.content_hash,
        file['.tag'],
        file.path_lower,
        '',
        file.path_lower
      )
    );
    const thumbnails = await Dropbox.getThumbnails(parsedFiles);
    const parsedResultsWithThumb = mergeFilesAndThumbnails(
      thumbnails,
      parsedFiles
    );
    const alreadyImported = await getAlreadyImported(
      result.matches.map((resultFile) => resultFile.metadata.metadata.id)
    );
    const withChangeAndSyncIncludes = parsedResultsWithThumb.map((file) => {
      const isImported = alreadyImported.findIndex(
        (dbFile) => dbFile.import.reference === file.external.id
      );
      let sync = {
        active: false,
        id: null,
      };
      const foundItem = jobs
        .filter((job) => job.scriptSource === 'dropbox')
        .find((item) => item.metadata.identifier === file.external.identifier);
      if (foundItem) {
        sync = { ...foundItem };
        sync.active = true;
        sync.id = foundItem._id;
        sync.manual = foundItem.type === 'manual';
      }
      const hasChanged =
        file.external.checksum !==
          alreadyImported[isImported]?.import.checksum &&
        typeof alreadyImported[isImported]?.import.checksum === 'string';

      return {
        ...file,
        media: {
          ...file.media,
          isImported: isImported >= 0,
        },
        hasChanged,
        sync,
        _id: alreadyImported[isImported]?._id,
      };
    });

    return {
      hasMore: result.has_more,
      alreadyImported,
      cursor: result.cursor,
      data: withChangeAndSyncIncludes,
    };
  };
  const mergeFilesAndThumbnails = (thumbnails, files) => {
    const parsedResultsWithThumb = [];
    for (const file of files) {
      const thumb = thumbnails.find(
        (thumb) => thumb.metadata?.path_lower === file.external.path
      );
      parsedResultsWithThumb.push({
        ...file,
        thumb: thumb?.thumbnail,
      });
    }
    return parsedResultsWithThumb;
  };
  const refreshPath = async (path, fetchMax, itemType) => {
    const files = await getProcessedFiles(
      path,
      false,
      false,
      true,
      itemType,
      fetchMax
    );
    while (
      files.data.length < dropboxFiles[path.replaceAll('/', '')]?.data.length ??
      100
    ) {
      const conFiles = await getProcessedFiles(
        path,
        files.cursor,
        false,
        true,
        itemType,
        fetchMax
      );
      files.hasMore = conFiles.hasMore;
      files.alreadyImported = [
        ...files.alreadyImported,
        ...conFiles.alreadyImported,
      ];
      files.cursor = conFiles.cursor;
      files.data = [...files.data, ...conFiles.data];
    }
    setDropboxFiles({
      type: 'SET',
      data: {
        path: path.replaceAll('/', ''),
        ...files,
      },
    });
    return files;
  };

  const getProcessedFiles = async (
    path,
    cursor,
    refresh,
    skipCache,
    itemType,
    fetchMax
  ) => {
    refresh = false;
    if (refresh) {
      return await refreshPath(path, fetchMax, itemType);
    }
    const account = await Dropbox.getAccount();
    const hasTeamSpaces =
      account?.root_info['.tag'] === 'team' ||
      account?.root_info?.root_namespace_id !==
        account?.root_info?.home_namespace_id;

    const noCursor = !cursor;
    const hasCache = false; // dropboxFiles[path.replaceAll('/', '')];
    const cacheExpired = path === '' && Date.now() > dropboxCache && !cursor;
    if (noCursor && hasCache && !cacheExpired && !skipCache) {
      return dropboxFiles[path.replaceAll('/', '')];
    }
    const files = await Dropbox.getFiles(
      path,
      cursor,
      hasTeamSpaces,
      account,
      fetchMax
    );
    let parsedFiles = await processFiles(files.entries);
    parsedFiles = parsedFiles.map((file) =>
      IntegrationHelpers.createFileObject(
        file.path_lower,
        file.name,
        file.content_hash,
        file['.tag'],
        file.path_lower,
        '',
        file.path_lower
      )
    );
    const thumbnails = await Dropbox.getThumbnails(parsedFiles);
    const parsedResultsWithThumb = mergeFilesAndThumbnails(
      thumbnails,
      parsedFiles
    );
    const ids = parsedResultsWithThumb
      .filter((file) => file.external.etag === 'file')
      .map((file) => file.external.id);
    const alreadyImported = await getAlreadyImported(ids);
    const withChangeAndSyncIncludes = parsedResultsWithThumb.map((file) => {
      const isImported = alreadyImported.findIndex(
        (dbFile) => dbFile.import.reference === file.external.identifier
      );
      let sync = {
        active: false,
        id: null,
      };
      const foundItem = jobs
        .filter((job) => job.scriptSource === 'dropbox')
        .find((item) => item.metadata.identifier === file.external.identifier);
      if (foundItem) {
        sync = { ...foundItem };
        sync.active = true;
        sync.id = foundItem._id;
        sync.manual = foundItem.type === 'manual';
      }
      const hasChanged =
        file.content_hash !== alreadyImported[isImported]?.import.checksum &&
        typeof alreadyImported[isImported]?.import.checksum === 'string';

      return {
        ...file,
        hasChanged,
        sync,
        _id: alreadyImported[isImported]?._id,
      };
    });

    const data = {
      path,
      hasMore: files.has_more,
      alreadyImported,
      cursor: files.cursor,
      data: withChangeAndSyncIncludes,
    };

    const itemData = { ...data };
    itemData.data =
      itemType === 'folders'
        ? data.data?.filter((folder) => folder.external.etag === 'folder')
        : data;

    if (cacheExpired) {
      setDropboxCache(Date.now() + 30 * 60 * 1000);
      setDropboxFiles({
        type: 'RESET',
        data: {
          path: path.replaceAll('/', ''),
          ...itemData,
        },
      });
    }

    if (noCursor) {
      setDropboxFiles({
        type: 'SET',
        data: {
          path: path.replaceAll('/', ''),
          ...itemData,
        },
      });
    } else {
      setDropboxFiles({
        type: 'PUSH',
        data: {
          path: path.replaceAll('/', ''),
          ...itemData,
        },
      });
    }

    if (itemType === 'folders') {
      return {
        ...data,
        data: itemData.data,
      };
    }
    return data;
  };

  const getAlreadyImported = async (ids, from = 'dropbox') => {
    const mediaFiles = await getChunkedReferences(Media, from, ids);
    const documentFiles = await getChunkedReferences(Documents, from, ids);
    return [...mediaFiles, ...documentFiles];
  };

  async function replaceFile(driveId, itemId, fileContent) {
    const data = await Dropbox.request(
      `/drives/${driveId}/items/${itemId}/content`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: fileContent,
      },
      'Sites.ReadWrite.All'
    );
    return data;
  }

  async function importFolder(item, prefs) {
    if (
      jobs.find(
        (job) => job.itemId === item.id && job.library === prefs.library
      )
    ) {
      return jobs.find(
        (job) => job.itemId === item.id && job.library === prefs.library
      );
    }
    const job = await User.request.files.importJobs.saveJob('dropbox', {
      itemId: item.identifier,
      driveId: item?.parentReference?.driveId,
      library: prefs.library,
      libraryCollection: prefs.libraryCollection,
      occurence: prefs.occurence,
      preferences: prefs.preferences,
      _cache: prefs._cache,
    });
    setJobs([
      ...jobs,
      {
        ...job,
        metadata: item,
      },
    ]);
  }

  const translations = {
    FETCHING_DRIVE: 'Fetching folder from Dropbox',
    FETCHING_ALL_FILES: 'Fetching all files from Dropbox',
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
    SOMETHING_WENT_WRONG: 'Something went wrong.',
    NO_ACCESS: 'Your Dropbox account does not have access to this resource.',
    NOT_VERIFIED: 'You need to verify your email at Dropbox.',
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  return {
    ...Dropbox,
    replaceFile,
    translateCode,
    getImportedFiles,
    getJobs,
    saveJob: async (updates, clearStatus) => {
      const newJob = await User.request.files.importJobs.saveJob(
        'dropbox',
        updates
      );
      setJobs(
        jobs.map((job) =>
          job._id === newJob._id
            ? { ...job, ...newJob, status: clearStatus ? null : job.status }
            : job
        )
      );
      return newJob;
    },
    deleteJob: async (jobId, prefs) => {
      await User.request.files.importJobs.deleteJob(jobId, prefs);
      if (prefs.deleteAllSyncedFiles) {
        Documents.setData({
          type: 'replace',
          store: 'files',
          data: Documents.data.files.filter(
            (file) => file?.import?.source !== jobId
          ),
        });
        Media.setData({
          type: 'replace',
          store: 'files',
          data: Media.data.files.filter(
            (file) => file?.import?.source !== jobId
          ),
        });
      }
      setJobs(jobs.filter((job) => job._id !== jobId));
    },

    getImportedReferences: async (items) => {
      const libraries = {
        media: [],
        documents: [],
      };
      items.forEach((item) => {
        if (item.name && !item.folder) {
          const extension = item.name?.split?.('.')?.pop();
          if (Media.isFileSupported(null, extension.toLowerCase())) {
            libraries.media.push(item.id);
          }
          if (Documents.isFileSupported(null, extension.toLowerCase())) {
            libraries.documents.push(item.id);
          }
        }
      });
      const mediaFiles = await getChunkedReferences(
        Media,
        'dropbox',
        libraries.media
      );
      const documentFiles = await getChunkedReferences(
        Documents,
        'dropbox',
        libraries.documents
      );
      return [...mediaFiles, ...documentFiles];
    },
    removeSyncJob,
    searchWithParsedData,
    getProcessedFiles,
    jobs,
    queueJob,
    jobQueue,
    syncJobs,
    importFolder,
    importedFiles: [
      ...(Media.data.imported_refs_dropbox || []),
      ...(Documents.data.imported_refs_dropbox || []),
    ],
  };
}
