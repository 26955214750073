import React, { useEffect, useReducer, useState } from 'react';
import { differenceInHours, isAfter, isBefore } from 'date-fns';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { useUserContext } from 'hooks/useUserContext';

import asyncPool from 'utils/async-pool';

const INTEGRATION = 'hubspot-export';
const INTEGRATION_NAME = 'HubSpot';

export default function useHubspotExport(props) {
  const { Hubspot } = props;
  const DAILY_SYNC_INTERVAL_HOURS = 4;
  const Media = useMediaContext();
  const Documents = useDocumentsContext();
  const User = useUserContext();
  const [rawJobs, setRawJobs] = useReducer((state, action) => [...action], []);
  const [jobs, setJobs] = useReducer((state, action) => {
    if (Array.isArray(action)) {
      return [...action];
    }
    if (action?.type === 'UPDATE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? { ...j, status: { ...j.status, ...action.status } }
          : j,
      );
    }
    if (action?.type === 'UPDATE_FILE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? {
              ...j,
              status: {
                ...j.status,
                files: {
                  ...(j.status?.files || {}),
                  [action.fileId]: action.status,
                },
              },
            }
          : j,
      );
    }
  }, []);

  const [forceJob, setForceJob] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [jobQueue, setJobQueue] = useReducer(
    (state, action) => [...action],
    [],
  );
  const [isRunningJobs, setIsRunningJobs] = useState(false);
  const [isLoadingJobs, setIsLoadingJobs] = useState(false);
  const [isDisconnectedWarning, setIsDisconnectedWarning] = useState(false);

  useEffect(() => {
    if (jobs.length) {
      setJobs(
        jobs.map((job) => {
          return {
            ...job,
          };
        }),
      );
    }
  }, [Media?.data?.collections?.length, Documents?.data?.collections?.length]);

  useEffect(() => {
    if (jobQueue?.length && !isRunningJobs) {
      runJobQueue();
    }
  }, [jobQueue, isRunningJobs]);

  useEffect(() => {
    if (jobs.length && Hubspot.isConnected) {
      const notChecked = jobs.filter((job) => {
        return (
          (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          job.status?.type !== 'ERROR' &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      });
      if (notChecked?.length) {
        setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
      }
      setIsDisconnectedWarning(false);
    }
    if (
      !Hubspot.isConnected &&
      !Hubspot.isConnecting &&
      !isLoadingJobs &&
      !!rawJobs.filter((job) => job.userId === User.djangoProfile.id)?.length
    ) {
      setIsDisconnectedWarning(true);
    }
  }, [
    Hubspot.isConnected,
    Hubspot.isConnecting,
    isLoadingJobs,
    jobs,
    rawJobs,
    isDisconnectedWarning,
  ]);

  async function queueJob(job, force) {
    if (!jobQueue.includes(job._id)) {
      setJobQueue([...jobQueue, job._id]);
    }
    if (force && !forceJob.includes(job._id)) {
      setForceJob([...forceJob, job._id]);
    }
  }

  async function runJobQueue() {
    const firstJob = jobQueue?.[0];
    if (isRunningJobs) {
      return false;
    }
    if (!firstJob) {
      setIsRunningJobs(false);
      return false;
    }
    const activeJob = jobs.find((job) => job._id === firstJob);
    if (!activeJob) {
      setIsRunningJobs(false);
      return false;
    }
    if (activeJob.status?.isRunning) {
      setJobQueue(jobQueue.filter((j, key) => !!key));
      return false;
    }
    setJobQueue(jobQueue.filter((j, key) => !!key));
    setIsRunningJobs(true);
    await runJob(activeJob);
    setIsRunningJobs(false);
  }

  async function getJobs() {
    setIsLoadingJobs(true);
    const data = await User.request.files.export.getJobs(INTEGRATION);
    data.jobs = data.jobs.filter(
      (job) => job.destination.siteType === INTEGRATION,
    );
    setRawJobs(data.jobs);
    const jobs = await asyncPool(10, data.jobs, async (job) => {
      const Library =
        job.library === 'media'
          ? Media
          : job.library === 'documents'
            ? Documents
            : null;
      let site;
      let drive;
      let folder;
      folder = await Hubspot.getFolderItem(job.destination.folderId);
      if (job.destination.folderId) {
        folder = await Hubspot.getFolderItem(job.destination.folderId);
        if (folder?.error) {
          job.error = {
            code: 'NO_ACCESS',
          };
          if (!job.status) job.status = {};
          job.status.type = 'ERROR';
        }
      }
      return {
        ...job,
        metadata: {
          site,
          drive,
          folder,
          collections: Library?.data?.collections?.filter((col) =>
            job.collections.includes(col._id),
          ),
        },
      };
    });
    setJobs(jobs);
    const notChecked = jobs.filter((job) => {
      return (
        (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&
        !jobQueue.includes(job._id) &&
        (job.occurence === 'daily' || !job.lastRun) &&
        job.status?.type !== 'ERROR' &&
        differenceInHours(new Date(), new Date(job.lastRun)) >=
          DAILY_SYNC_INTERVAL_HOURS
      );
    });
    setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
    setIsLoadingJobs(false);
  }

  async function runJob(job) {
    function updateJobStatus(status) {
      setJobs({ type: 'UPDATE_STATUS', jobId: job._id, status });
    }
    try {
      const Library =
        job.library === 'media'
          ? Media
          : job.library === 'documents'
            ? Documents
            : null;

      if (
        job.userId !== User.djangoProfile?.id &&
        !forceJob.includes(job._id)
      ) {
        updateJobStatus({
          type: 'ERROR',
          code: 'NOT_CREATOR',
          isLoading: false,
          isRunning: false,
          isChecked: true,
          current: 0,
          total: 0,
        });
        return false;
      }

      if (!Library) {
        updateJobStatus({
          type: 'ERROR',
          code: 'NO_LIBRARY_SELECTED',
          files: [],
        });
        return false;
      }

      if (job.error?.code) {
        return updateJobStatus({
          code: job.error?.code,
          isError: true,
          isRunning: false,
        });
      }

      updateJobStatus({
        code: 'GETTING_PICKIT_FILES',
        isRunning: true,
        isLoading: true,
      });
      const map = [];
      const folderMap = job.maps?.collections || [];
      let filesMap = job.maps?.files || [];
      const allFiles = [];
      const allItems = [];
      let baseFolder;
      try {
        baseFolder = await Hubspot.getFolderItem(job.destination.folderId);
      } catch (e) {}
      const useBaseFolder =
        job.preferences.createFolders === false && !!baseFolder;

      if (baseFolder === 404) {
        return updateJobStatus({
          type: 'ERROR',
          code: 'FOLDER_REMOVED',
          isRunning: false,
          isLoading: false,
          isChecked: true,
          current: 0,
          total: 0,
        });
      }

      job.collections = job.collections.filter(
        (c) => !!Library.stores?.data?.collections?.find((ca) => ca._id === c)
      );

      await asyncPool(2, job.collections, async (collection) => {
        const metadata = await Library.getCollection(collection);
        let collectionFiles = await Library.getAllFilesInCollection(collection);
        collectionFiles = collectionFiles.filter(
          (file) => !!file.file?.url && !file?.file?.external_file,
        );
        const mapItem = job.maps?.collections?.find(
          (map) => map.collectionId === collection,
        );
        let item;
        allFiles.push(...collectionFiles);
        if (mapItem && !useBaseFolder) {
          item = await Hubspot.getFolderItem(mapItem.itemId);
          if (item.error) {
            item = null;
            folderMap.splice(
              folderMap.findIndex((it) => it.collectionId === collection),
              1,
            );
          }
        }
        map.push({
          collection: metadata,
          files: collectionFiles,
          item: useBaseFolder ? baseFolder : item,
        });
      });

      updateJobStatus({ code: 'CREATING_FOLDERS' });
      await asyncPool(1, map, async (mapItem) => {
        let { item } = mapItem;
        const name = mapItem.collection.name
          .trim()
          .replace(/^[._]|[!#%&*{}:<>?/|"~]|\.{2}|\.$|(?:\.files)/g, '_');
        if (!item) {
          item = await Hubspot.createFolder(
            job?.metadata?.folder?.path_lower || job.destination.folderId,
            name,
          );
          if (item.archived) {
            // item = await Hubspot.deleteItem(`${job.destination.folderId}/${name}`);
            // item = await Hubspot.createFolder(job?.metadata?.folder?.path_lower || job.destination.folderId, name);
            item = await Hubspot.restoreFolder(
              `${job.destination.folderId}/${name}`,
            );
          }
          if (item.error) {
            item = await Hubspot.getFolderItem(
              encodeURI(`${job.destination.folderId}/${name}`),
            );
          }
          folderMap.push({
            itemId: item.id,
            collectionId: mapItem.collection._id,
          });
        }
        if (item && item.name !== name && !useBaseFolder) {
          await Hubspot.renameFolder(item.id, name, item.name);
        }
        // This might not work. Replace with findIndex if so.
        map[map.indexOf(mapItem)] = {
          ...mapItem,
          item,
        };
      });

      updateJobStatus({ code: 'GETTING_FILES_IN_FOLDERS' });
      await asyncPool(2, map, async (mapItem) => {
        const items = await Hubspot.getAllFilesFromFolder(
          mapItem.item.id,
          true,
        );
        allItems.push(...(items || []));
        // Removing files which has been deleted in Sharepoint, hence the map is no longer valid.
        map[map.indexOf(mapItem)] = {
          ...mapItem,
          items,
        };
      });

      filesMap = filesMap.filter((mp) =>
        allItems.find((it) => it.external.id === mp.itemId),
      );

      updateJobStatus({ code: 'CHECKING_FILE_ALREADY_EXISTS' });
      await asyncPool(2, map, async (mapItem) => {
        const files = mapItem.files.map((file) => {
          return {
            ...file,
            alreadyUploaded: !!filesMap.find(
              (item) => item.fileId === file._id,
            ),
          };
        });
        map[map.indexOf(mapItem)] = {
          ...mapItem,
          files,
        };
      });

      const filesDeleted = filesMap.filter((fileMap) => {
        let isDeleted = true;
        map.find((mapItem) => {
          if (mapItem.files.find((file) => file._id === fileMap.fileId)) {
            isDeleted = false;
          }
        });
        return isDeleted;
      });
      if (filesDeleted?.length) {
        const current = 0;
        updateJobStatus({
          code: 'DELETING_REMOVED_FILES',
          current,
          total: filesDeleted?.length,
        });
        await asyncPool(2, filesDeleted, async (item) => {
          await Hubspot.deleteItem(item.itemId);
          filesMap = filesMap.filter((it) => it.itemId !== item.itemId);
          updateJobStatus({ current, total: filesDeleted?.length });
        });
      }

      let sasToken = await Library.getToken(Library.sasToken);

      function fileShouldImport(file) {
        let { file: fileToUpload } = file;
        if (!file.file.ext) return;
        const ext = file.file.ext.toLowerCase();
        const fileSize =
          job.preferences.imageSizeSpecific?.[file._id] ||
          job.preferences.imageSize?.[ext] ||
          'source';

        if (!job.preferences.imageSize?.[ext]) {
          return false;
        }
        if (
          fileSize === 'largest-preview' &&
          fileToUpload.contentType !== 'images/gif' &&
          !fileToUpload.contentType.includes('video') &&
          !fileToUpload.contentType.includes('audio') &&
          file.file.previews?.[0]
        ) {
          fileToUpload = file.file.previews?.[0];
        }
        if (fileSize === 'ignore') {
          return false;
        }
        if (!fileToUpload) {
          return false;
        }
        return true;
      }
      async function uploadFile(file, replace) {
        sasToken = await Library.getToken(sasToken);
        let { file: fileToUpload } = file;
        const item = file?.destinationItem?.external;
        let token = sasToken?.token?.main;
        let fileName = file?.name || file.file.name;
        const ext = file.file.ext.toLowerCase();
        if (!fileName.includes(`.${ext}`)) {
          fileName = `${fileName}.${ext}`;
        }
        const fileSize =
          job.preferences.imageSizeSpecific?.[file._id] ||
          job.preferences.imageSize?.[ext] ||
          'source';

        if (!job.preferences.imageSize?.[ext]) {
          return null;
        }
        if (
          fileSize === 'largest-preview' &&
          fileToUpload.contentType !== 'images/gif' &&
          !fileToUpload.contentType.includes('video') &&
          !fileToUpload.contentType.includes('audio') &&
          file.file.previews?.[0]
        ) {
          fileToUpload = file.file.previews?.[0];
          token = sasToken?.token?.thumbnails;
        }
        if (fileSize === 'ignore') {
          return null;
        }
        if (!fileToUpload) {
          return null;
        }
        if (fileToUpload.ext !== file.file.ext) {
          fileName = `${fileName.split('.')[0]}.jpg`;
        }
        current += 1;

        if (!replace) {
          const uploadedFile = await Hubspot.uploadFile(
            file.destinationItem.id,
            `${fileToUpload.url?.split('?')?.[0]}?${token}`,
            fileName.includes('%20') ? decodeURIComponent(fileName) : fileName,
          );
          filesMap.push({
            itemId: uploadedFile?.id,
            fileId: file._id,
          });
        } else {
          const oldExt = item.path.split('.')?.pop();
          const newFile = await Hubspot.uploadFile(
            file.destinationItem.external.parentId,
            `${fileToUpload.url?.split('?')?.[0]}?${token}`,
            oldExt !== ext
              ? fileName.includes('%20')
                ? decodeURIComponent(fileName)
                : fileName
              : `${item.name}.${item.path.split('.')?.pop()}`,
            true,
          );
          filesMap = filesMap.map((it) => {
            if (it.fileId === file._id) {
              return {
                ...it,
                itemId: newFile.id,
              };
            }
            return it;
          });
        }
      }

      const filesUpdated = filesMap
        .filter((fileMap) => {
          const file = allFiles.find((file) => file._id === fileMap.fileId);
          const item = allItems.find(
            (item) => item.external.id === fileMap.itemId,
          );
          if (!file || !item) {
            return false;
          }
          const itemExportedDate = new Date(item.external.lastUpdated);
          const fileUploadedDate = new Date(file.file.uploaded_at);
          return isAfter(fileUploadedDate, itemExportedDate);
        })
        .map((fileMap) => ({
          ...allFiles.find((file) => file._id === fileMap.fileId),
          destinationItem: allItems.find(
            (item) => item.external.id === fileMap.itemId,
          ),
        }));
      updateJobStatus({
        code: 'UPLOADING_FILE_CHANGES',
        current,
        total: filesUpdated?.length,
      });
      await asyncPool(2, filesUpdated, async (file) => {
        await uploadFile(file, true);
        updateJobStatus({ current, total: filesNotUploaded?.length });
      });

      const filesNotUploaded = map.reduce((files, mapItem) => {
        return [
          ...files,
          ...mapItem.files
            .filter((file) => !file.alreadyUploaded && fileShouldImport(file))
            .map((file) => ({
              ...file,
              destinationItem: mapItem.item,
            })),
        ];
      }, []);

      let current = 0;
      updateJobStatus({
        code: 'UPLOADING_FILES',
        current,
        total: filesNotUploaded?.length,
      });
      await asyncPool(2, filesNotUploaded, async (file) => {
        await uploadFile(file);
        current += 1;
        updateJobStatus({ current, total: filesNotUploaded?.length });
      });

      if (filesMap?.length) {
        await User.request.files.export.saveJob(INTEGRATION, {
          _id: job._id,
          lastRun: new Date(),
          maps: {
            collections: [...folderMap],
            files: [...filesMap],
          },
        });
      }

      updateJobStatus({
        code: 'JOB_CHECKED',
        isLoading: false,
        isRunning: false,
        isChecked: true,
        current: 0,
        total: 0,
      });
      /**
       * Check if Job collection has been deleted
       */
      const updatedJob = await User.request.files.export.saveJob(INTEGRATION, {
        _id: job._id,
        lastRun: new Date(),
        collections: job.collections.map((c) => c?._id || c),
      });
      setJobs(
        jobs.map((job) =>
          job._id === updatedJob._id
            ? {
                ...job,
                ...updatedJob,
                status: {
                  code: 'JOB_CHECKED',
                  isLoading: false,
                  isRunning: false,
                  isChecked: true,
                  current: 0,
                  total: 0,
                },
              }
            : job,
        ),
      );
    } catch (e) {
      console.log(e);
      updateJobStatus({
        type: 'ERROR',
        code: Object.keys(translations).includes(e) ? e : 'JOB_FAILED',
        error: e,
        isRunning: false,
        isLoading: false,
        isChecked: true,
        current: 0,
        total: 0,
      });
      return false;
    }
  }

  const translations = {
    GETTING_PICKIT_FILES: 'Fetching all Pickit files in collections',
    CREATING_FOLDERS: 'Creating folders at destination',
    GETTING_FILES_IN_FOLDERS: 'Getting files in created folders at destination',
    CHECKING_FILE_ALREADY_EXISTS: 'Checking which files already is uploaded',
    DELETING_REMOVED_FILES: 'Deleting removed Pickit files',
    UPLOADING_FILE_CHANGES: 'Uploading file changes from Pickit files',
    UPLOADING_FILES: 'Uploading files',
    JOB_CHECKED: 'Export completed successfully',
    NO_PERMISSION:
      'The destination you are trying it export to is not accessible by your account.',
    FOLDER_REMOVED:
      "The folder you are trying to export to doesn't exist anymore.",
    JOB_FAILED: 'Something went wrong. Please try again or contact support',
    QUEUED: 'Waiting for other export to finish...',
    NO_SPACE: 'Your Hubspot account is out of free space.',
    NO_ACCESS: "You don't have access to this resource at Hubspot",
    NOT_CREATOR: (
      <>
        You are not the creator of this export.
        <br />
        You can force it to sync at your own risk.
      </>
    ),
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  const deleteJob = async (jobId, prefs) => {
    await User.request.files.export.deleteJob(INTEGRATION, jobId, prefs);
    setJobs(jobs.filter((job) => job._id !== jobId));
  };

  const saveJob = async (updates, clearStatus, metadata) => {
    try {
      const newJob = await User.request.files.export.saveJob(
        INTEGRATION,
        updates,
      );
      if (!updates._id) {
        setJobs([
          ...jobs,
          {
            ...newJob,
            metadata,
          },
        ]);
        queueJob({
          ...newJob,
          metadata,
        });
        return newJob;
      }
      setJobs(
        jobs.map((job) =>
          job._id === newJob._id
            ? {
                ...job,
                ...newJob,
                metadata,
                status: clearStatus ? null : job.status,
              }
            : job,
        ),
      );
      return newJob;
    } catch (e) {
      return e;
    }
  };

  return {
    ...Hubspot,
    translateCode,
    INTEGRATION,
    INTEGRATION_NAME,
    saveJob,
    deleteJob,
    isDisconnectedWarning,
    jobs,
    queueJob,
    jobQueue,
    isLoadingJobs,
    getJobs,
  };
}
