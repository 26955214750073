import React, { cloneElement, lazy } from 'react';

interface InterfacePixiFlagIcon extends React.ComponentPropsWithoutRef<'div'> {
  /** Name of the bootstrap icon */
  name: string;
}

export default React.forwardRef<HTMLDivElement, InterfacePixiFlagIcon>(
  ({ name, ...rest }) => {
    if (!name) {
      return <></>;
    }
    return (
      <img
        {...rest}
        alt=""
        src={`https://embed.pickit.com/images/flags/${name}.svg`}
      />
    );
  }
);
