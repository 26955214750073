import React, { ReactNode, useCallback, useRef } from 'react';
import { useDndMonitor, useDroppable } from '@dnd-kit/core';
import { Center, Overlay, rgba } from '@mantine/core';
import { useId, useMergedRef } from '@mantine/hooks';
import { AppConfigInterface } from '@pixi/AppController';
import {
  useConfigStoreCallback,
  useConfigStoreValue,
  useDataStoreCallback,
} from '@pixi/store';
import PixiText from '@pixi/elements/Text';
import useElementViewport from '@pixi/hooks/useElementViewport';
import AppGlobalEvents from '@pixi/AppGlobalEvents';

const Droppable = ({
  onDrop,
  label,
  isDisabled: _isDisabled,
  accepts,
  alwaysShowLabel,
  blocked,
}: {
  onDrop: (data: AppConfigInterface['draggingAsset']) => void;
  label: (data: {
    isOver: boolean;
    isActive: boolean;
    file?: Pickit.FileInterface | null;
  }) => ReactNode;
  alwaysShowLabel?: boolean;
  accepts: AppConfigInterface['draggingAsset']['type'];
  isDisabled?: (data?: {
    id?: string;
    type?: AppConfigInterface['draggingAsset']['type'];
    file?: Pickit.FileInterface | null;
  }) => boolean;
  blocked?: (data?: {
    id?: string;
    type?: AppConfigInterface['draggingAsset']['type'];
    file?: Pickit.FileInterface | null;
  }) => {
    isBlocked: boolean;
    message?: ReactNode;
    backgroundColor?: string;
  };
}) => {
  const uniqueId = useId();
  const draggingAsset = useConfigStoreCallback(
    'APP_CONFIG',
    'draggingAsset',
    (data) => {
      if (!data?.id) {
        return null;
      }
      if (!accepts || data?.type === accepts) {
        return data;
      }
      return null;
    },
    [accepts],
  );
  const draggingFile = useDataStoreCallback(
    'FILES',
    (_, state) => {
      if (!draggingAsset?.id) {
        return null;
      }
      return state.getByKey(draggingAsset?.id);
    },
    [draggingAsset?.id],
  );
  const { ref, viewport } = useElementViewport();
  const { isOver: _isOver, setNodeRef } = useDroppable({
    id: uniqueId,
  });
  const mergedRef = useMergedRef(ref, setNodeRef);

  const isDraggingAssetAcceptable =
    (!accepts || accepts === draggingAsset?.type) && !!draggingAsset?.id;

  const blockedData = blocked?.({
    id: draggingAsset?.id,
    type: draggingAsset?.type,
    file: draggingFile,
  });
  const isOver =
    _isOver && isDraggingAssetAcceptable && !blockedData?.isBlocked;
  const isDisabled =
    !isDraggingAssetAcceptable ||
    !draggingAsset?.type ||
    (_isDisabled
      ? _isDisabled({
          id: draggingAsset?.id,
          type: draggingAsset?.type,
          file: draggingFile,
        })
      : false);

  const Label = useCallback(() => {
    return label?.({
      isOver,
      isActive: isDraggingAssetAcceptable,
      file: draggingFile,
    });
  }, [label, isOver, isDraggingAssetAcceptable, draggingFile]);
  AppGlobalEvents.useListener(
    'assetDrop',
    (droppedId: string, asset) => {
      if (
        droppedId === uniqueId &&
        isDraggingAssetAcceptable &&
        !isDisabled &&
        !blockedData?.isBlocked &&
        asset?.type === accepts
      ) {
        onDrop(asset);
      }
    },
    {},
    [uniqueId, isDraggingAssetAcceptable, isDisabled, blockedData?.isBlocked],
  );

  const fontSize =
    viewport === 'xxs'
      ? 12
      : viewport === 'xs'
        ? 22
        : viewport === 'sm'
          ? 28
          : viewport === 'md'
            ? 34
            : viewport === 'lg'
              ? 46
              : 56;

  return (
    <>
      <Overlay
        zIndex={99999999}
        ref={mergedRef}
        bg="transparent"
        style={{
          ...(isDisabled
            ? {
                pointerEvents: 'none',
              }
            : {}),
        }}
      >
        {isDisabled && !alwaysShowLabel ? (
          <></>
        ) : (
          <>
            <Overlay
              w="100%"
              h="100%"
              bg={
                isDisabled
                  ? 'transparent'
                  : blockedData?.isBlocked && blockedData?.backgroundColor
                    ? blockedData?.backgroundColor
                    : 'dark'
              }
              opacity={isOver ? 1 : isDraggingAssetAcceptable ? 0.5 : 0}
              zIndex={1}
            />
            <Center h="100%" pos="relative" style={{ zIndex: 11 }}>
              <PixiText fz={fontSize} c={alwaysShowLabel ? 'gray.6' : 'white'}>
                {blockedData?.isBlocked ? blockedData?.message : <Label />}
              </PixiText>
            </Center>
          </>
        )}
      </Overlay>
    </>
  );
};

export default Droppable;
