import { Paper } from '@mantine/core';
import PixiDropdown from './elements/Dropdown';
import AppInformationDropdown from './components/AppInformationDropdown';
import System from './System';

export default function AppPreviewBanner() {
  if (window.location.origin === 'pickit.com') {
    return <></>;
  }
  return (
    <PixiDropdown
      width={400}
      openDelay={1000}
      trigger="hover"
      target={
        <Paper
          w={40}
          h={40}
          pos="fixed"
          bg="transparent"
          style={{ zIndex: 9999, pointerEvents: 'auto' }}
          bottom={0}
          p={5}
          fw="bold"
          left={0}
          fz={10}
        />
      }
      position="top"
    >
      <AppInformationDropdown />
      <PixiDropdown.Divider />
      <PixiDropdown.Label>Developer tools</PixiDropdown.Label>
      <PixiDropdown.Item
        icon="exclamation"
        color="red"
        onClick={async () => {
          await System.Report.logError(new Error('Fake error'));
        }}
      >
        Report fake error
      </PixiDropdown.Item>
    </PixiDropdown>
  );
}
