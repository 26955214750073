import System from '@pixi/System';
import {
  Box,
  Button,
  ButtonProps,
  Group,
  Modal,
  ModalProps,
  Paper,
  ScrollArea,
  Stack,
  StackProps,
} from '@mantine/core';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';
import { isPromise } from '@pixi/helpers/utils';
import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactNode,
  useState,
} from 'react';
import { useColorScheme } from '@pixi/AppController';
import { mergeDeep } from 'immutable';

export type PixiPopupProps<C = 'div'> = PolymorphicComponentProps<
  C,
  ModalProps
> & {
  actions?: ReactNode;
  fullHeight?: boolean;
  noScroll?: boolean;
  bodyProps?: Omit<StackProps, 'children'>;
};

const PixiPopup = forwardRef(
  (props: PixiPopupProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, actions, fullHeight, noScroll, bodyProps, ...rest } =
      props;
    const colorScheme = useColorScheme();

    return (
      <Modal
        ref={ref}
        {...rest}
        styles={mergeDeep(rest.styles || {}, {
          root: {
            ...(fullHeight ? { height: '100%' } : {}),
          },
          title: {
            width: '100%',
          },
          inner: {
            ...(fullHeight
              ? {
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
              : {}),
            ...(rest?.styles?.inner || {}),
          },
          header: {
            background:
              colorScheme === 'dark'
                ? 'var(--mantine-color-dark-8)'
                : 'var(--mantine-color-gray-1)',
            padding: 'var(--mantine-spacing-xl)',
          },
          content: {
            ...(typeof rest?.size === 'number' || rest?.size?.includes('%')
              ? { width: '100%', maxWidth: rest.size }
              : {}),
            ...(fullHeight
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'stretch',
                  overflow: 'hidden',
                }
              : {}),
            ...(rest?.styles?.content || {}),
          },
          body: {
            ...(fullHeight
              ? {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: 0,
                }
              : {}),
            padding: 0,
            ...(rest?.styles?.body || {}),
          },
        })}
      >
        {noScroll ? (
          <Stack h="100%" mih={1} pos="relative">
            {children}
          </Stack>
        ) : (
          <ScrollArea h={fullHeight ? '100%' : 'auto'}>
            <Box h="100%" mih={1} pos="relative">
              {children}
            </Box>
          </ScrollArea>
        )}
        {actions ? (
          <Paper
            w="100%"
            p="lg"
            bg={colorScheme === 'dark' ? 'dark.8' : 'gray.0'}
            style={{ flexShrink: 0 }}
          >
            <Group w="100%" gap={0} wrap="nowrap">
              {actions}
            </Group>
          </Paper>
        ) : (
          <></>
        )}
      </Modal>
    );
  },
);

export default PixiPopup;
