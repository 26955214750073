import { Button, Flex, Icon, Popup } from 'elements_v2';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { PinkToolbar } from 'elements_v2/PinkToolbar';
import Color from 'color';
import { analytics } from 'services';
import Input from 'elements_v2/Input/index_ts';
import TermsConfirm from './helpers/TermsConfirm';
import PixiButton from '@pixi/elements/Button';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiConfirm from '@pixi/elements/Confirm';
import LinkForm from './helpers/LinkForm';
import PixiTooltip from '@pixi/elements/Tooltip';

export const Link = forwardRef(
  ({
    link,
    type,
    Module,
    onChange,
    onRemove,
    forceEditing,
    onClick,
    props,
    termsOfUse,
  }) => {
    const [label, setLabel] = useState(link.label);
    const [isEditing, setIsEditing] = useState(false);
    const toolbarRef = useRef();
    const { ref: dragRef, ...rest } = props;

    useEffect(() => {
      if (forceEditing) {
        setIsEditing(true);
      }
    }, [forceEditing]);

    let icon = type === 'download' ? 'download' : 'BoxArrowUpRight';

    if (link.type === 'collection' && type !== 'download') {
      icon = 'collection';
    }
    if (link.type === 'board') {
      icon = 'layers';
    }
    if (link.type === 'email') {
      icon = 'envelope-fill';
    }

    if (!Module.isEditing) {
      const button = (
        <Button
          key={link.value}
          inline
          as="div"
          onClick={(event) => {
            onClick(event, label);
          }}
          className={Module.metadata?.buttonBackground ? 'no_global_style' : ''}
          background={Module.metadata?.buttonBackground || '#6716d8'}
          style={{
            display: 'inline-flex',
            marginLeft: 0,
            color: Module.metadata?.buttonForeground || '#FFF',
            overflow: 'hidden',
            maxWidth: '100%',
          }}
          contentStyle={{
            wordWrap: 'break-word',
            maxWidth: '100%',
          }}
        >
          <Icon name={icon} style={{ flexShrink: 0 }} />
          <span
            className="BoardBodyText BoardBodyText--onlyFont"
            style={{
              wordWrap: 'break-word',
              maxWidth: '100%',
            }}
          >
            {label}
          </span>
        </Button>
      );
      if (termsOfUse) {
        return <TermsConfirm trigger={button} content={termsOfUse} />;
      }
      return button;
    }

    const isDeleted = link.type === 'file' && !link?.data?._id;

    console.log(isDeleted);

    const button = (
      <PixiButton
        key={link.value}
        ref={dragRef}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          overflow: 'hidden',
          display: 'inline-flex',
          color: isDeleted
            ? '#FFF'
            : Module.metadata?.buttonForeground || '#FFF',
        }}
        color={isDeleted ? 'red' : undefined}
        className={
          isDeleted
            ? 'no_global_style'
            : Module.metadata?.buttonBackground
              ? 'no_global_style'
              : ''
        }
        background={
          isDeleted ? 'red' : Module.metadata?.buttonBackground || '#6716d8'
        }
        dropdownProps={{
          width: 240,
          classNameDropdown: 'link_menu',
        }}
        dropdown={
          Module.isEditing
            ? (prefs) => (
                <>
                  <LinkForm
                    dropdownRenderProps={prefs}
                    variant={
                      link.type === 'board'
                        ? 'board'
                        : link.type === 'collection'
                          ? link.library === 'media'
                            ? 'media_collection'
                            : 'document_collection'
                          : link.type === 'file'
                            ? 'file'
                            : link.type === 'email'
                              ? 'email'
                              : 'custom'
                    }
                    library={link.library}
                    value={
                      link.type === 'collection'
                        ? {
                            collection: link.data,
                            label,
                          }
                        : link.type === 'board'
                          ? {
                              board: link.data,
                              label,
                              preferences: link.preferences,
                            }
                          : link.type === 'file'
                            ? {
                                file: link.data,
                                label,
                              }
                            : {
                                url: link.value,
                                label,
                                preferences: link.preferences,
                              }
                    }
                    onSubmit={(data) => {
                      onChange(link.value, {
                        label: data.label,
                        value:
                          data.board?._id || data.collection?._id || data?.url,
                        type: link.type,
                        library: link.library,
                        preferences: data.preferences,
                      });
                    }}
                    zIndex={59}
                    target={
                      <PixiDropdown.Item icon="pencil">
                        Edit link
                      </PixiDropdown.Item>
                    }
                  />
                  <PixiConfirm
                    title="Are you sure?"
                    description="The link will be deleted"
                    confirmLabel="Delete link"
                    onConfirm={() => {
                      onRemove();
                    }}
                  >
                    <PixiDropdown.Item icon="trash-can">
                      Delete link
                    </PixiDropdown.Item>
                  </PixiConfirm>
                </>
              )
            : undefined
        }
        leftSection={!isEditing && <Icon name={icon} />}
        rootProps={{
          ...rest.listeners,
          ...rest.attributes,
          style: {
            ...(rest.style || {}),
          },
        }}
      >
        {label}
      </PixiButton>
    );

    return (
      <div
        ref={toolbarRef}
        style={{
          maxWidth: '100%',
          position: 'relative',
          zIndex: 15,
          display: 'inline-flex',
          alignItems: 'flex-start',
        }}
      >
        {isDeleted ? (
          <PixiTooltip label="File has been deleted" isForceOpen color="red">
            {button}
          </PixiTooltip>
        ) : (
          button
        )}
      </div>
    );
  },
);
