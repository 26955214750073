import { differenceInHours } from 'date-fns';
import { useDocumentsContext } from 'hooks/useDocumentsContext';
import { useMediaContext } from 'hooks/useMediaContext';
import { useUserContext } from 'hooks/useUserContext';
import { useEffect, useReducer, useState } from 'react';
import asyncPool from 'utils/async-pool';
import { googleMimetoExt } from 'utils';
import localStorageWrapper from 'utils/localStorageWrapper';

const defaultFileFields = [
  'id',
  'name',
  'parents',
  'mimeType',
  'md5Checksum',
  'modifiedTime',
  'createdTime',
  'webViewLink',
  'webContentLink',
  'fileExtension',
  'fullFileExtension',
  'thumbnailLink',
  'size',
  'lastModifyingUser/displayName',
  'driveId',
];

export default function useShutterstock(props) {
  const DAILY_SYNC_INTERVAL_HOURS = 4;
  const Media = useMediaContext();
  const [isConnected] = useState(true);
  const Documents = useDocumentsContext();
  const API_BASE =
    window.location.href.includes('.dev') ||
    window.location.href.includes('localhost')
      ? 'https://api-sandbox.shutterstock.com'
      : 'https://api.shutterstock.com';
  const User = useUserContext();
  const [jobs, setJobs] = useReducer((state, action) => {
    if (Array.isArray(action)) {
      return [...action];
    }
    if (action?.type === 'UPDATE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? { ...j, status: { ...j.status, ...action.status } }
          : j
      );
    }
    if (action?.type === 'UPDATE_FILE_STATUS') {
      return state.map((j) =>
        j._id === action.jobId
          ? {
              ...j,
              status: {
                ...j.status,
                files: {
                  ...(j.status?.files || {}),
                  [action.fileId]: action.status,
                },
              },
            }
          : j
      );
    }
  }, []);

  const [jobQueue, setJobQueue] = useReducer(
    (state, action) => [...action],
    []
  );
  const [isRunningJobs, setIsRunningJobs] = useState(false);

  useEffect(() => {
    if (jobQueue?.length && !isRunningJobs) {
      runJobQueue();
    }
  }, [jobQueue, isRunningJobs]);

  useEffect(() => {
    if (jobs.length && !jobQueue?.length) {
      const notChecked = jobs.filter((job) => {
        return (
          /* (!job.status || (!job.status?.isRunning && !job.status?.isChecked)) &&*/
          !jobQueue.includes(job._id) &&
          (job.occurence === 'daily' || !job.lastRun) &&
          differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        );
      });
      setJobQueue([...jobQueue, ...notChecked.map((job) => job._id)]);
    }
  }, [jobs]);

  async function getImportedFiles() {
    await Promise.all(
      ['media', 'documents'].map(async (type) => {
        if (type === 'media') {
          await Media.importGetFiles('shutterstock');
        }
        if (type === 'documents') {
          await Documents.importGetFiles('shutterstock');
        }
      })
    );
  }

  async function queueJob(job) {
    setJobQueue([...jobQueue, job._id]);
  }

  async function runJobQueue() {
    const firstJob = jobQueue?.[0];
    if (!JSON.parse(localStorageWrapper.getItem('shutterstock'))?.accessToken) {
      return false;
    }
    if (isRunningJobs) {
      return false;
    }
    if (!firstJob) {
      setIsRunningJobs(false);
      return false;
    }
    const activeJob = jobs.find((job) => job._id === firstJob);
    if (activeJob?.status?.isRunning) {
      setJobQueue(jobQueue.filter((j, key) => !!key));
      return false;
    }
    setJobQueue(jobQueue.filter((j, key) => !!key));
    setIsRunningJobs(true);
    await runJob(activeJob);
    setIsRunningJobs(false);
  }

  async function getJobs() {
    const data = await User.request.files.importJobs.getJobs('shutterstock');

    const jobs = await Promise.all(
      data.jobs.map(async (job) => {
        let metadata;
        if (job.itemId) {
          try {
            metadata = await getCollection(
              job.itemId,
              job?.metadata?.type || job.driveId
            );
          } catch (e) {
            console.error(
              `failed getting drive item for job. drive: ${job.driveId} | item: ${job.itemId}`
            );
          }
        }
        return {
          ...job,
          metadata,
        };
      })
    );

    setJobs(jobs);
    setJobQueue(
      jobs
        .filter(
          (job) =>
            differenceInHours(new Date(), new Date(job.lastRun)) >=
            DAILY_SYNC_INTERVAL_HOURS
        )
        .map((job) => job._id)
    );
  }
  async function licenseAsset(id, isEditorial, type) {
    const token = JSON.parse(
      localStorageWrapper.getItem('shutterstock')
    )?.accessToken;
    const { subscription, customer } = JSON.parse(
      localStorageWrapper.getItem('shutterstock')
    );
    const data = {
      [type]: [
        {
          [`${type.replace('s', '')}_id`]: id,
          metadata: {
            customer_id: customer.id,
            purchase_order: id,
          },
        },
      ],
    };
    if (isEditorial) {
      data.images[0].editorial_acknowledgement = true;
    }
    const URL = `${API_BASE}/v2/${type}/licenses?subscription_id=${subscription.id}`;
    const OPTIONS = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    const response = await fetch(URL, OPTIONS);
    if (response.status === 200) {
      const result = await response.json();
      if (!result.errors?.length) {
        return result.data[0].download.url;
      }
    }
    return null;
  }
  async function getAlreadyDownloadedURL(id, type) {
    const token = JSON.parse(
      localStorageWrapper.getItem('shutterstock')
    ).accessToken;
    const URL = `${API_BASE}/v2/${type}/licenses/${id}/downloads`;
    const OPTIONS = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        size: type === 'images' ? 'huge' : '4k',
      }),
    };
    const response = await fetch(URL, OPTIONS);
    const result = await response.json();
    return result.url;
  }

  async function getAllLicenses(id, type) {
    const token = JSON.parse(
      localStorageWrapper.getItem('shutterstock')
    ).accessToken;
    const OPTIONS = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
      },
    };
    let page = 1;
    let totalCount = 1;
    let data = [];
    while (totalCount > data.length) {
      const URL = `${API_BASE}/v2/${type}/licenses?per_page=200&page=${page}`;
      const response = await fetch(URL, OPTIONS);
      const result = await response.json();
      data = [...data, ...result.data];
      totalCount = result.total_count;
      page++;
    }
    return data;
  }

  async function getAssetURL(id, inHistory, isEditorial, type) {
    const data = {};
    /* if (!inHistory) {
      const licensingInfo = await this.getLicensingInfo(id);
      data.credits = licensingInfo.available_entitlement.quota;
      data.canLicense = licensingInfo.purchase_options.state === 'possible';
      data.licensingMessage = licensingInfo.purchase_options.message;
    }*/
    if (!inHistory) {
      const assetURL = await licenseAsset(id, isEditorial, type);
      data.url = assetURL;
    } else if (inHistory) {
      const assetURL = await getAlreadyDownloadedURL(id, type);
      data.url = assetURL;
    }
    return data;
  }

  async function runJob(job) {
    const licenses = await getAllLicenses(
      null,
      job?.metadata?.type || job.driveId
    );
    if (!job?.library) return;
    const Library =
      job.library === 'media'
        ? Media
        : job.library === 'documents'
        ? Documents
        : null;

    if (!Library) {
      updateJobStatus({
        type: 'ERROR',
        code: 'NO_LIBRARY_SELECTED',
        files: [],
      });
      return false;
    }

    function updateJobStatus(status) {
      setJobs({ type: 'UPDATE_STATUS', jobId: job._id, status });
    }
    function updateFileStatus(file, status) {
      setJobs({
        type: 'UPDATE_FILE_STATUS',
        jobId: job._id,
        fileId: file.id,
        status: { ...status, data: file },
      });
    }

    /**
     * Check if Job collection has been deleted
     */
    const isSyncingToCollection = job.libraryCollection;
    const collection = job.libraryCollection
      ? Library.data.collections.find(
          (collection) => collection._id === job.libraryCollection
        )
      : false;

    if (isSyncingToCollection && !collection?._id) {
      updateJobStatus({
        code: 'ERROR_COLLECTION_DELETED',
        isLoading: false,
        isError: true,
      });
      return false;
    }
    /**
     * end check
     */

    /* updateJobStatus({ code: 'FETCHING_DRIVE', isLoading: true, files: [] });

    updateJobStatus({ isRunning: true });
    const item = await getDriveItem(job.driveId, job.itemId);*/

    // Get all files ## NESTED
    updateJobStatus({ code: 'FETCHING_ALL_FILES', files: [] });
    let files = await getAllFilesFromCollection(
      job.itemId,
      job?.metadata?.type || job.driveId
    );
    files = await files.filter((file) => file.external.id);

    const fileIds = files.map((file) => file.external.identifier);

    // Get all imported files
    updateJobStatus({ code: 'CHECKING_ALREADY_IMPORTED', isLoading: true });
    const importedReferences = await Library.importCheckReferences(
      'shutterstock',
      fileIds
    );

    let filesNotImported = files.filter(
      (file) =>
        !importedReferences.find(
          (f) => f?.import?.reference === file.external.identifier
        )
    );
    let filesModified = files.filter((file) => {
      const importedFile = importedReferences.find(
        (f) => f?.import?.reference === file.id
      );
      if (!importedFile) {
        return false;
      }

      if (
        importedFile &&
        new Date(importedFile.file.uploaded_at).getTime() <
          new Date(file.modifiedTime).getTime()
      ) {
        updateFileStatus(file, { type: 'ACTION', code: 'FILE_CHANGES_EXISTS' });
        return true;
      }
      updateFileStatus(file, { type: 'SUCCESS', code: 'IMPORTED' });
      return false;
    });

    const tmpFilesNotImported = [];
    for (const file of filesNotImported) {
      const type = job?.metadata?.type || job.driveId;
      if (
        licenses.find(
          (license) => license[type.replace('s', '')].id === file.external.id
        ) === undefined
      ) {
        tmpFilesNotImported.push({
          ...file,
          inHistory: false,
        });
      } else {
        tmpFilesNotImported.push({
          ...file,
          external: {
            ...file.external,
            metadata: {
              licenseId: licenses.find(
                (license) =>
                  license[type.replace('s', '')].id === file.external.id
              ).id,
            },
          },
          inHistory: true,
        });
      }
    }
    filesNotImported = tmpFilesNotImported;
    if (job.lastRun !== null) {
      filesNotImported = filesNotImported.filter((image) => image.inHistory);
    }

    const filesNotInCollection = !job.libraryCollection
      ? []
      : importedReferences.filter(
          (file) => !file?.collections?.includes(job.libraryCollection)
        );

    if (filesNotInCollection?.length) {
      updateJobStatus({ code: 'ADDING_FILES_TO_COLLECTION', files: [] });
      await asyncPool(5, filesNotInCollection, async (file) => {
        if (!file?.collections?.includes(job.libraryCollection)) {
          updateFileStatus(file, {
            type: 'PENDING',
            code: 'ADDING_FILE_TO_COLLECTION',
          });
          await Library.saveDocument({
            _id: file._id,
            collections: [...(file.collections || []), job.libraryCollection],
          });
          updateFileStatus(file, {
            type: 'PENDING',
            code: 'ADDED_FILE_TO_COLLECTION',
          });
        }
      });
    }

    if (filesModified?.length) {
      updateJobStatus({
        code: 'REPLACING_UPDATED_FILES',
        current,
        total: filesModified?.length,
      });
      await asyncPool(5, filesModified, async (file) => {
        const importedFile = importedReferences.find(
          (f) => f.import.reference === file.id
        );
        // TODO: Reserach how to get a download url from the file, and send taht to this function.
        if (!importedFile?.file?.external_file) {
          await Library.replaceMedia(
            file.webContentLink
              ? `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`
              : file.webViewLink,
            importedFile._id,
            'shutterstock',
            file.md5Checksum,
            {
              requestHeaders: {
                authorization: `Bearer ${
                  window.gapi.auth.getToken().access_token
                }`,
              },
              approval: !!job.requireApproval,
            }
          );
        }
        updateJobStatus({ current, total: files?.length });
      });
      filesModified = [];
    }

    if (
      !filesModified?.length &&
      !filesNotImported?.length &&
      !filesNotInCollection?.length
    ) {
      updateJobStatus({ code: 'JOB_CHECKED', isLoading: false });
    }

    filesNotImported.map((file) =>
      updateFileStatus(file, { type: 'SUCCESS', code: 'IMPORT_QUEUED' })
    );

    let current = files?.length - filesNotImported?.length;

    if (filesNotImported?.length) {
      updateJobStatus({
        code: 'IMPORTING_FILES',
        current,
        total: files?.length,
      });
    }

    await asyncPool(
      5,
      filesNotImported.filter((file) => file?.external?.id),
      async (file) => {
        return new Promise(async (resolve, reject) => {
          const contributionData = file;
          let data = {};
          try {
            data = await getAssetURL(
              file.inHistory
                ? contributionData.external.metadata.licenseId
                : contributionData.external.id,
              file.inHistory,
              contributionData.external.metadata.isEditorial,
              job?.metadata?.type || job.driveId
            );
          } catch (e) {
            console.error(e);
          }
          if (!data?.url) {
            current += 1;
            updateJobStatus({ current, total: files?.length });
            updateFileStatus(file, { code: 'UPLOADED', isLoading: false });
            updateJobStatus({
              code: 'JOB_CHECKED',
              isLoading: false,
              isRunning: false,
              isChecked: true,
              current: 0,
              total: 0,
            });
            await User.request.files.importJobs.saveJob('shutterstock', {
              _id: job._id,
              lastRun: new Date(),
            });
            return;
          }
          const linkData = await fetch(data.url, {
            redirect: 'follow',
            mode: 'no-cors',
          });
          const mimeType = linkData.headers.get('Content-Type');
          const disposition = linkData.headers.get('Content-Disposition');
          const fileName = disposition?.split('"').pop();
          let ext = '';
          switch (mimeType) {
            case 'application/postscript':
              ext = '.eps';
              break;
            case 'application/eps':
              ext = '.eps';
              break;
            case 'application/x-eps':
              ext = '.eps';
              break;
            case 'image/eps':
              ext = '.eps';
              break;
            case 'image/x-eps':
              ext = '.eps';
              break;
            case 'image/tiff':
              ext = '.tiff';
              break;
            case 'image/svg+xml':
              ext = '.svg';
              break;
            case 'image/gif':
              ext = '.gif';
              break;
            case 'image/png':
              ext = '.png';
              break;
            case 'image/jpeg':
              ext = '.jpeg';
              break;
            case 'image/jpg':
              ext = '.jpg';
              break;
            default:
              ext = '';
          }

          if (data.url) {
            await Library.importMedia(
              data.url,
              {
                description: contributionData.external.description,
                file: {
                  name:
                    fileName ||
                    contributionData.external.metadata.originalFilename ||
                    contributionData.external.identifier + ext,
                },
                import: {
                  from: 'shutterstock',
                  reference: contributionData.external.identifier,
                  source: job._id,
                },
                uploadedAt: new Date(),
                collections: job.libraryCollection
                  ? [job.libraryCollection]
                  : undefined,
              },
              null,
              {
                useSocket: true,
                onProcess: (status) => {
                  updateFileStatus(file, {
                    type:
                      status.status === 'ALREADY_UPLOADED'
                        ? 'ERROR'
                        : 'PENDING',
                    ...status,
                  });
                },
                onClose: () => {
                  current += 1;
                  updateJobStatus({ current, total: files?.length });
                  updateFileStatus(file, {
                    code: 'UPLOADED',
                    isLoading: false,
                  });
                  resolve(true);
                },
                onError: () => {
                  reject();
                },
              }
            );
          }
        });
      }
    );
    updateJobStatus({
      code: 'JOB_CHECKED',
      isLoading: false,
      isRunning: false,
      isChecked: true,
      current: 0,
      total: 0,
    });
    const updatedJob = await User.request.files.importJobs.saveJob(
      'shutterstock',
      {
        _id: job._id,
        lastRun: new Date(),
      }
    );
    setJobs(
      jobs.map((job) =>
        job._id === updatedJob._id
          ? {
              ...job,
              ...updatedJob,
              status: {
                code: 'JOB_CHECKED',
                isLoading: false,
                isRunning: false,
                isChecked: true,
                current: 0,
                total: 0,
              },
            }
          : job
      )
    );
  }

  async function getCollectionCostData(id, type) {
    const priceData = {};
    const collection = await getCollection(id, type);
    priceData.total = collection.total_items_count;
    const collectionImages = await getCollectionImages(id, type);
    const collectionIds = collectionImages
      .map((image) => image.external.id)
      .filter((id) => !!id);
    priceData.total = collectionIds.length;
    let owned = collectionIds.length;
    const licenses = await getAllLicenses(null, type);
    for (const colId of collectionIds) {
      if (
        licenses.find(
          (license) => license[type.replace('s', '')].id === colId
        ) === undefined
      ) {
        owned -= 1;
      }
    }
    priceData.owned = owned;
    return priceData;
  }

  async function getCollectionImages(id, type) {
    const token = JSON.parse(
      localStorageWrapper.getItem('shutterstock')
    ).accessToken;
    const response = await fetch(
      `${API_BASE}/v2/${type}/collections/${id}/items`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    const imageIds = result.data.map((image) => image.id);
    const promises = imageIds.map((id) =>
      fetch(`${API_BASE}/v2/${type}/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const promisesResponses = await Promise.all(promises);
    const promisesResults = await Promise.all(
      promisesResponses.map((chain) => chain.json())
    );
    // eslint-disable-next-line no-restricted-syntax
    const finalResult = promisesResults.map((image) =>
      createFileObject(
        image.id,
        image.id,
        image.id,
        'file',
        null,
        image.assets?.large_thumb?.url ||
          image.assets?.huge_thumb?.url ||
          image.assets?.small_thumb?.url ||
          image.assets?.thumb_jpg?.url ||
          '',
        image.id,
        { originalFilename: image.original_filename },
        image.description
      )
    );

    return finalResult;
  }

  /**
   * Get all files from a specific folder
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a drive shared with the user.
   * @param {string} itemId ID of a folder or alias "root" for the root folder
   */
  async function getAllFilesFromCollection(collectionId, type) {
    const token = JSON.parse(
      localStorageWrapper.getItem('shutterstock')
    ).accessToken;
    const response = await fetch(
      `${API_BASE}/v2/${type}/collections/${collectionId}/items`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();
    const imageIds = result.data.map((image) => image.id);
    const promises = imageIds.map((id) =>
      fetch(`${API_BASE}/v2/${type}/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const promisesResponses = await Promise.all(promises);
    const promisesResults = await Promise.all(
      promisesResponses.map((chain) => chain.json())
    );

    const finalResults = [];
    for (const image of promisesResults) {
      const licenseResponse = await fetch(
        `${API_BASE}/v2/${type}/licenses?${type.replace('s', '')}_id=${
          image.id
        }`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const licenseResult = await licenseResponse.json();
      const isLicensed = licenseResult?.data?.download?.url;
      finalResults.push(
        createFileObject(
          image.id,
          image.id,
          image.id,
          'file',
          null,
          image.assets?.large_thumb?.url ||
            image.assets?.huge_thumb?.url ||
            image.assets?.small_thumb?.url ||
            image.assets?.thumb_jpg?.url ||
            '',
          image.id,
          {
            originalFilename: image.original_filename,
            isEditorial: image.is_editorial,
            isLicensed,
          },
          image.description
        )
      );
    }
    return finalResults;
  }
  /**
   * Get all shared drives. Shared drives are drives created by a Google Workplace and is not owned by a specific user
   */
  async function getAllSharedDrives() {
    let result = await getSharedDrives();

    const results = [...result.drives];
    let { nextPageToken } = result;
    while (nextPageToken) {
      result = await getSharedDrives(nextPageToken);
      result.drives.forEach((drive) => results.push(drive));
      nextPageToken = result.nextPageToken;
    }

    return results;
  }
  /**
   * Get a specific file
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a shared drive.
   * @param {string} itemId ID of a file or folder
   */
  async function getCollection(itemId, type) {
    const token = JSON.parse(
      localStorageWrapper.getItem('shutterstock')
    ).accessToken;
    const URL = `${API_BASE}/v2/${type}/collections/${itemId}`;
    const OPTIONS = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = await fetch(URL, OPTIONS);
    const result = await response.json();
    return result;
  }
  /**
   * Get Items in a folder
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a shared drive.
   * @param {string} itemId ID of a folder or alias "root" for the root folder
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function getFolderItems(
    driveId,
    folderId,
    nextPageToken,
    previousResult = []
  ) {
    const params = {
      fields: `nextPageToken, files(${defaultFileFields.join(',')})`,
      q: `"${folderId || driveId || 'root'}" in parents`,
      pageSize: 100,
    };
    if (driveId) {
      params.corpora = 'drive';
      params.driveId = driveId;
      params.supportsAllDrives = true;

      params.includeItemsFromAllDrives = true;
    }
    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await props.Google.request(
      `drive/v3/files`,
      { params },
      'auth/drive.metadata.readonly'
    );
    const files = data.files.map((item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        item.folder = true;
      }
      return item;
    });

    const concatFiles = previousResult.concat(files);

    if (data.nextPageToken) {
      return await getFolderItems(
        driveId,
        folderId,
        data.nextPageToken,
        concatFiles
      );
    }
    return concatFiles;
  }
  /**
   * Get recent files
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function getRecentFiles(nextPageToken) {
    const params = {
      fields: `nextPageToken,  files(${defaultFileFields.join(',')})`,
      orderBy: 'modifiedByMeTime desc',
      q: "mimeType != 'application/vnd.google-apps.folder'",
    };
    params.supportsAllDrives = true;
    params.includeItemsFromAllDrives = true;

    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await props.Google.request(
      `drive/v3/files`,
      { params },
      'auth/drive.metadata.readonly'
    );
    data.files = data.files.map((item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        item.folder = true;
      }
      return item;
    });
    return data;
  }

  /**
   * Get a shared drive. Shared drives are drives created by a Google Workplace and is not owned by a specific user
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function getSharedDrives(nextPageToken) {
    const params = {};
    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await props.Google.request(
      `drive/v3/drives`,
      { params },
      'auth/drive.metadata.readonly'
    );

    return data;
  }

  /**
   * Get Items in a folder
   * @param {string} query Query to search for.
   * @param {bool} includeFromAllDrives Whether search should apply to all drives the user can access
   * @param {string} driveId Leave undefined for users own drive or provide an ID for a shared drive.
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function search(query, includeFromAllDrives, driveId, nextPageToken) {
    const params = {
      fields: `nextPageToken, files(${defaultFileFields.join(',')})`,
      q: `name contains "${query}"`,
    };
    if (driveId) {
      params.corpora = 'drive';

      params.driveId = driveId;
    }
    if (includeFromAllDrives || driveId) {
      params.includeItemsFromAllDrives = true;
      params.supportsAllDrives = true;
    }
    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await props.Google.request(
      `drive/v3/files`,
      { params },
      'auth/drive.metadata.readonly'
    );
    data.files = data.files.map((item) => {
      if (item.mimeType === 'application/vnd.google-apps.folder') {
        item.folder = true;
      }
      return item;
    });
    return data;
  }
  /**
   * Find drives of name
   * @param {string} query Query to search for.
   * @param {string} nextPageToken Token provided from a previous request signifying the next page to get
   */
  async function searchForSharedDrive(query, nextPageToken) {
    const params = {
      q: `name contains '${query}'`,
    };

    if (nextPageToken) {
      params.pageToken = nextPageToken;
    }

    const data = await props.Google.request(
      `drive/v3/drives`,
      { params },
      'auth/drive.metadata.readonly'
    );

    return data.drives;
  }

  async function importFolder(item, prefs) {
    if (
      jobs.find(
        (job) => job.itemId === item.id && job.library === prefs.library
      )
    ) {
      return jobs.find(
        (job) => job.itemId === item.id && job.library === prefs.library
      );
    }
    const job = await User.request.files.importJobs.saveJob('shutterstock', {
      itemId: item.id,
      driveId: item?.type,
      library: prefs.library,
      libraryCollection: prefs.libraryCollection,
      occurence: prefs.occurence,
      _cache: prefs._cache,
    });
    setJobs([
      ...jobs,
      {
        ...job,
        metadata: item,
      },
    ]);
  }

  const translations = {
    FETCHING_DRIVE: 'Fetching Shutterstock',
    FETCHING_ALL_FILES: 'Fetching all files from Shutterstock',
    CHECKING_ALREADY_IMPORTED: 'Checking for any file changes',
    REPLACING_UPDATED_FILES: 'Replacing changed files',
    CONNECTED: 'Starting upload',
    UPLOADING: 'Uploading file',
    PROCESSING: 'Processing file',
    AI: 'Processing file (AI)',
    JOB_CHECKED: 'All files imported',
    IMPORTING_FILES: 'Uploading files',
    ADDING_FILES_TO_COLLECTION: 'Adding already uploaded files to collection',
    ERROR_COLLECTION_DELETED: 'Sync failed. Collection has been deleted.',
  };

  const createFileObject = (
    id = null,
    name = '',
    checksum = null,
    etag = null,
    path = null,
    thumb = '',
    identifier,
    externalMetadata = {},
    description = ''
  ) => {
    return {
      media: {
        supported:
          etag === 'file'
            ? [].includes(name.split('.').pop().toLowerCase())
            : true,
      },
      external: {
        id,
        name,
        identifier: identifier?.toString() || id?.toString(),
        checksum,
        description,
        metadata: externalMetadata,
        path,
        etag,
        thumb,
        ext: etag === 'file' ? name.split('.').pop().toLowerCase() : null,
      },
    };
  };

  function translateCode(code) {
    if (translations[code]) {
      return translations[code];
    }
    return code;
  }

  return {
    getFolderItems,
    getRecentFiles,
    getSharedDrives,
    getAllSharedDrives,
    search,
    searchForSharedDrive,
    translateCode,

    getImportedFiles,
    getJobs,

    saveJob: async (updates, clearStatus) => {
      const newJob = await User.request.files.importJobs.saveJob(
        'shutterstock',
        updates
      );
      setJobs(
        jobs.map((job) =>
          job._id === newJob._id
            ? { ...job, ...newJob, status: clearStatus ? null : job.status }
            : job
        )
      );
      return newJob;
    },

    deleteJob: async (jobId, prefs) => {
      await User.request.files.importJobs.deleteJob(jobId, prefs);
      if (prefs.deleteAllSyncedFiles) {
        Documents.setData({
          type: 'replace',
          store: 'files',
          data: Documents.data.files.filter(
            (file) => file?.import?.source !== jobId
          ),
        });
        Media.setData({
          type: 'replace',
          store: 'files',
          data: Media.data.files.filter(
            (file) => file?.import?.source !== jobId
          ),
        });
      }

      setJobs(jobs.filter((job) => job._id !== jobId));
    },

    getImportedReferences: async (items) => {
      const libraries = {
        media: [],
        documents: [],
      };
      items.forEach((item) => {
        if (item.name && !item.folder) {
          const extension =
            item.fileExtension || googleMimetoExt(item.mimeType);

          if (Media.isFileSupported(null, extension?.toLowerCase())) {
            libraries.media.push(item.id);
          }
          if (Documents.isFileSupported(null, extension?.toLowerCase())) {
            libraries.documents.push(item.id);
          }
        }
      });
      const mediaFiles = await Media.importCheckReferences(
        'shutterstock',
        libraries.media
      );
      const documentFiles = await Documents.importCheckReferences(
        'shutterstock',
        libraries.documents
      );
      return [...mediaFiles, ...documentFiles];
    },

    jobs,
    queueJob,
    isConnected,
    jobQueue,
    getCollectionCostData,

    importFolder,
    importedFiles: [
      ...(Media.data?.imported_refs_shutterstock || []),
      ...(Documents.data?.imported_refs_shutterstock || []),
    ],
  };
}
