import { useDraggable } from '@dnd-kit/core';
import {
  Badge,
  Box,
  Checkbox,
  Group,
  Loader,
  LoadingOverlay,
  Overlay,
  Paper,
  PaperProps,
  Stack,
  Table,
} from '@mantine/core';
import { useIntersection, useMergedRef } from '@mantine/hooks';
import { useColorScheme } from '@pixi/AppController';
import PixiButton from '@pixi/elements/Button';
import PixiIcon from '@pixi/elements/Icon';
import PixiText, { PixiTitle } from '@pixi/elements/Text';
import PixiTooltip from '@pixi/elements/Tooltip';
import { TransparentPattern } from '@pixi/elements/TransparentPattern';
import { getFileExtension } from '@pixi/helpers/FileUtils';
import { useAssetThumbnail } from '@pixi/hooks/useAssetThumbnail';
import {
  getMultiStore,
  useConfigStoreCallback,
  useConfigStoreValue,
  useMultiDataStoreCallback,
} from '@pixi/store';
import {
  ForwardedRef,
  MouseEvent,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { truncate } from 'utils';
import { AssetThumbnail } from '../../AssetThumbnail';
import { AssetContextMenu, AssetDropdownRender } from '../../AssetActions';
import { AssetGridProps } from '../Helpers';
import AssetTableRow from './AssetTableRow';
import useDelayedState from 'hooks/useDelayedState';
import { useAssetGridContext } from '../AssetGridContext';
import { useAppContext } from 'contexts/Providers/AppProvider';
import PixiDropdown from '@pixi/elements/Dropdown';
import Droppable from '@pixi/components/Droppable';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';
import { useUserContext } from 'hooks';
import { getDefaultAssetToolbar } from '@pixi/Vars';
import { isNative } from 'utils/platform';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { NativeDragWrapper } from 'views/Native';
import { isBefore } from 'date-fns';
import { AssetActionIds } from '@pixi/components/AssetActions/Types';

export default function AssetCard(
  props: {
    onClick?: (
      event: MouseEvent<HTMLDivElement>,
      fileToolbar: ReturnType<typeof useAssetActions>,
    ) => void;
    file: Pickit.FileInterface;
    gridId?: string;
    as?: 'image' | 'card' | 'table';
    fields?: string[];
    fileToolbar?: AssetGridProps['fileToolbar'];
    fileToolbarCustom?: AssetGridProps['fileToolbarCustom'];
    containerRef?: React.RefObject<HTMLDivElement>;
    style?: React.CSSProperties;
  } & PaperProps,
) {
  const { allFiles, viewportRef } = useAssetGridContext();
  const index = allFiles.findIndex((f) => f._id === props.file._id);
  const { ref: intersectionRef, entry } = useIntersection({
    threshold: 0,
    rootMargin: '500px',
    root: viewportRef?.current,
  });
  const [isVisible, setIsVisible] = useState(index <= 20);

  useEffect(() => {
    if (entry?.isIntersecting !== isVisible && index > 20) {
      setIsVisible(entry?.isIntersecting || false);
    }
  }, [entry?.isIntersecting, isVisible]);

  const fileCardWrapper = useMemo(() => {
    if (!isVisible) {
      return <></>;
    }
    if (!props.file?._id) {
      return <></>;
    }
    return <FileCardWrapper isVisible={isVisible} {...props} />;
  }, [isVisible]);

  if (props.as === 'table') {
    if (!props.file?._id) {
      return <></>;
    }
    return <FileCardWrapper isVisible={isVisible} {...props} />;
  }
  return (
    <Box
      w="100%"
      style={{
        aspectRatio: '4/3.5',
        ...props.style,
      }}
      bg={!isVisible ? 'gray.0' : undefined}
      ref={intersectionRef}
      pos="relative"
    >
      {fileCardWrapper}
    </Box>
  );
}
const FileCardWrapper = (
  {
    file,
    onClick,
    gridId,
    as,
    fields,
    fileToolbar: _fileToolbar,
    fileToolbarCustom,
    containerRef,
    isVisible,
    ...rest
  }: {
    onClick?: (
      event: MouseEvent<HTMLDivElement>,
      fileToolbar: ReturnType<typeof useAssetActions>,
    ) => void;
    file: Pickit.FileInterface;
    gridId?: string;
    as?: 'image' | 'card' | 'table';
    fields?: string[];
    fileToolbar?: AssetGridProps['fileToolbar'];
    fileToolbarCustom?: AssetGridProps['fileToolbarCustom'];
    containerRef?: React.RefObject<HTMLDivElement>;
    isVisible: boolean;
    ref?: any;
  } & PaperProps,
  _ref: ForwardedRef<HTMLDivElement>,
) => {
  const fileToolbar =
    _fileToolbar || (['favorite', 'menu'] as AssetActionIds[]);
  const { activeShortcutIds, disable, id, readOnly, multiSelect } =
    useAssetGridContext();
  const { imageType } = useAssetThumbnail({
    file,
  });
  const App = useAppContext();
  const draggingAsset = useConfigStoreValue('APP_CONFIG', 'draggingAsset');
  const ref = useRef<HTMLDivElement | HTMLTableRowElement>(null);
  const colorScheme = useColorScheme();
  const fileToolbarHook = useAssetActions({
    file,
  });
  const { specificActions } = fileToolbarHook;
  const {
    attributes,
    listeners,
    setNodeRef,
    isDragging: _isDragging,
  } = useDraggable({
    id: file._id,
    data: {
      type: 'file',
    },
    disabled: isNative(),
  });
  const { generateUrl } = useStorageUrl();
  const isDragging = draggingAsset?.id === file._id && _isDragging;
  const combinedRef = useMergedRef(ref, setNodeRef, _ref);
  const [isLoading, setIsLoading] = useState(false);
  const _isSelected = useMultiDataStoreCallback(
    'FILES_SELECTED',
    gridId || 'general',
    (data) => {
      return !!data.getByRow(file);
    },
    [gridId, file._id],
  );
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const isSelected = _isSelected;

  const probablyHasAlpha =
    imageType === 'svg' ||
    (imageType === 'preview' &&
      (file?.file?.ext === 'ai' ||
        file?.file?.ext === 'eps' ||
        file?.file?.ext === 'png'));

  const hasPreviewVideo = !!file?.file?.previewVideo?.id;
  const isHighlight = isSelected;

  let render = <></>;

  if (as === 'table') {
    render = (
      <Table.Tr
        style={{
          zIndex: isContextMenuOpen ? 2 : 1,
          cursor: 'pointer',
          ...(isContextMenuOpen
            ? {
                outline: '2px solid var(--mantine-color-primary-5)',
              }
            : {}),
          ...(isDragging
            ? {
                transform: 'scale(0.5)',
                opacity: 0.2,
              }
            : {}),
        }}
        ref={combinedRef as any}
        pos="relative"
        {...listeners}
        {...attributes}
      >
        <Overlay
          bg="primary"
          opacity={isHighlight ? 0.3 : 0}
          top={0}
          left={0}
          style={{
            borderRadius: 'var(--mantine-radius-md)',
          }}
          onClick={(event) => onClick?.(event, fileToolbarHook)}
          zIndex={5}
        />
        {isVisible && (
          <>
            <AssetContextMenu
              onTrigger={setIsContextMenuOpen}
              contextMenuRef={ref}
              file={file}
              zIndex={150}
              readOnly={readOnly}
            />
            <AssetTableRow
              isHighlight={isHighlight}
              file={file}
              fields={fields}
              fileToolbarHook={fileToolbarHook}
            />
          </>
        )}
      </Table.Tr>
      // </Table.Tr>
    );
  }

  const approvalNeeded =
    file?.approval?.status &&
    file?.approval?.status !== 'approved' &&
    file?.approval?.status !== 'rejected';

  let warning = '';

  if (
    file?.approval?.status === 'rejected' ||
    (file?.license?.expirationDate &&
      isBefore(new Date(file?.license?.expirationDate), new Date())) ||
    file?.share_to === 'none'
  ) {
    warning =
      file?.approval?.status === 'rejected'
        ? 'Asset has been rejected'
        : file?.share_to === 'none'
          ? 'Asset is shared to admins only'
          : 'License has expired';
  }

  if (as !== 'table') {
    render = (
      <Paper
        w="100%"
        style={{
          aspectRatio: '4/3.5',
          overflow: 'hidden',
          cursor: 'pointer',
          userSelect: 'none',
          transition: 'transform .15s',
          transform: 'scale(1)',
          ...(isContextMenuOpen
            ? {
                outline: '2px solid var(--mantine-color-primary-5)',
              }
            : {}),
          ...(isDragging
            ? {
                transform: 'scale(0.5)',
                opacity: 0.2,
              }
            : {}),
        }}
        pos="relative"
        bg={isHighlight ? 'primary.0' : 'transparent'}
        ref={combinedRef}
        onMouseOver={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        {...rest}
        {...listeners}
        {...attributes}
      >
        <>
          <Overlay
            bg="primary"
            opacity={isHighlight ? 0.3 : 0}
            top={0}
            left={0}
            onClick={async (event) => {
              setIsLoading(true);
              await onClick?.(event, fileToolbarHook);
              setIsLoading(false);
            }}
            zIndex={5}
          />
          {isVisible && (
            <AssetContextMenu
              onTrigger={setIsContextMenuOpen}
              contextMenuRef={ref}
              file={file}
              zIndex={150}
              readOnly={readOnly}
            />
          )}
          {(!!fileToolbarCustom?.length ||
            !!fileToolbar?.length ||
            App.isManage) && (
            <Box
              w="100%"
              pos="absolute"
              top={0}
              style={{
                zIndex: 10,
              }}
            >
              <Group w="100%" gap="0" justify="space-between" p="5">
                <Group gap="3">
                  {!disable?.includes('selection') &&
                    (isMouseOver || _isSelected) && (
                      <PixiTooltip label="Select file">
                        <Checkbox
                          size="md"
                          checked={_isSelected}
                          onChange={() => {
                            if (!multiSelect) {
                              getMultiStore('FILES_SELECTED', id).replace(
                                _isSelected ? [] : [{ _id: file._id }],
                              );
                              return;
                            }
                            getMultiStore('FILES_SELECTED', id).toggle({
                              _id: file._id,
                            });
                          }}
                        />
                      </PixiTooltip>
                    )}
                  {approvalNeeded && App.isManage && (
                    <PixiTooltip label="Approval needed">
                      <PixiButton
                        px="5"
                        size="compact-xs"
                        color="primary"
                        variant="filled"
                        onClick={() => {
                          getMultiStore('FILES_SELECTED', id).toggle({
                            _id: file._id,
                          });
                        }}
                      >
                        <PixiIcon
                          name="triangle-exclamation"
                          variant="filled"
                        />
                      </PixiButton>
                    </PixiTooltip>
                  )}
                  {warning && (
                    <PixiTooltip label={warning}>
                      <PixiButton px="5" size="compact-xs" variant="white">
                        <PixiIcon
                          name="triangle-exclamation"
                          variant="filled"
                        />
                      </PixiButton>
                    </PixiTooltip>
                  )}
                  {file?._modifiers?.cosineSimilarity &&
                    activeShortcutIds?.find((s) => s.includes('ai.')) && (
                      <PixiTooltip label="Similarity score">
                        <PixiButton px="5" size="compact-xs" variant="white">
                          {Math.floor(file?._modifiers?.cosineSimilarity * 100)}
                          %
                        </PixiButton>
                      </PixiTooltip>
                    )}
                  {file?.file?.external_file && (
                    <PixiTooltip label="External file">
                      <PixiButton
                        px="5"
                        size="compact-xs"
                        color="dark"
                        variant="white"
                      >
                        <PixiIcon name="link-horizontal" />
                      </PixiButton>
                    </PixiTooltip>
                  )}
                </Group>
                <Group
                  gap="3"
                  style={{
                    opacity: isSelected || isMouseOver ? 1 : 0,
                    transition: 'opacity .1s',
                    transformOrigin: 'top right',
                  }}
                >
                  {specificActions(
                    fileToolbar.filter((p) => p !== 'menu'),
                    false,
                  )?.map((tool) => {
                    const trigger = (
                      <PixiTooltip label={tool.label} key={tool.id}>
                        <PixiButton
                          variant={tool.color ? 'filled' : 'white'}
                          size="compact-xs"
                          px="3"
                          color="dark"
                          onClick={(event) => {
                            tool.onClick?.(event);
                          }}
                        >
                          <PixiIcon
                            size="sm"
                            name={tool.icon || '00'}
                            {...tool.iconProps}
                          />
                        </PixiButton>
                      </PixiTooltip>
                    );
                    if (tool.customRender) {
                      return tool.customRender(trigger, {
                        isOpen: true,
                        onClose: () => {},
                        setIsFreezeDropdown: () => {},
                      });
                    }
                    return trigger;
                  })}
                  {(isMouseOver || isContextMenuOpen || isMenuOpen) &&
                    fileToolbar?.includes('menu') && (
                      <PixiDropdown
                        width={240}
                        zIndex={150}
                        target={
                          <PixiButton
                            variant="white"
                            size="compact-xs"
                            px="3"
                            color="dark"
                          >
                            <PixiIcon name="ellipsis" />
                          </PixiButton>
                        }
                        onOpen={() => {
                          setIsMenuOpen(true);
                          setIsContextMenuOpen(true);
                        }}
                        onClose={() => {
                          setIsMenuOpen(false);
                          setIsContextMenuOpen(false);
                        }}
                        customRender={({ isOpen, setIsOpen }) => (
                          <AssetDropdownRender
                            isOpen={isOpen}
                            rootzIndex={150}
                            onClose={() => setIsOpen(false)}
                            file={file}
                            actions={specificActions(
                              getDefaultAssetToolbar(file, {
                                readOnly,
                              }),
                              false,
                            )}
                          />
                        )}
                      />
                    )}
                  {fileToolbarCustom?.map((tool) => {
                    const trigger = (
                      <PixiTooltip label={tool.label}>
                        <PixiButton variant="white" size="compact-xs" px="5">
                          <PixiIcon name={tool.icon} size="xs" />
                        </PixiButton>
                      </PixiTooltip>
                    );
                    if (tool.wrapper) {
                      return tool.wrapper(trigger, file);
                    }
                    return trigger;
                  })}
                </Group>
              </Group>
            </Box>
          )}
          <Stack h="100%" gap={0}>
            <Group
              w="100%"
              h="100%"
              mih={1}
              justify="center"
              align="center"
              p={as !== 'image' && imageType !== 'icon' ? undefined : undefined}
              bg={colorScheme === 'dark' ? 'dark.5' : 'gray.1'}
              pos="relative"
            >
              {isLoading && <LoadingOverlay top={0} visible={isLoading} />}
              {isVisible && (
                <>
                  {file?.processing?.thumbnail?.isProcessing && (
                    <Overlay
                      w="100%"
                      h="100%"
                      color="#FFF"
                      zIndex={99}
                      bg={isHighlight ? 'primary.1' : 'gray.0'}
                      onClick={async (event) => {
                        setIsLoading(true);
                        await onClick?.(event, fileToolbarHook);
                        setIsLoading(false);
                      }}
                    >
                      <Stack
                        align="center"
                        justify="center"
                        h="100%"
                        c="dark"
                        gap="xs"
                      >
                        <Loader color="dark" size="sm" />
                        <PixiText fw="600" size="xs">
                          Generating thumbnail
                        </PixiText>
                      </Stack>
                    </Overlay>
                  )}
                  {!!file?.file?.previews?.length && (
                    <TransparentPattern
                      color={colorScheme === 'dark' ? 'dark.7' : 'gray.2'}
                    />
                  )}
                  <Box w="100%" h="100%" pos="relative">
                    {(isMouseOver || imageType === 'icon') && (
                      <Badge
                        pos="absolute"
                        variant="default"
                        style={{ zIndex: 3 }}
                        bottom={5}
                        left={5}
                      >
                        {getFileExtension(file)}
                      </Badge>
                    )}
                    {hasPreviewVideo && isMouseOver && (
                      <video
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          zIndex: 3,
                          background: '#000',
                          objectFit: 'contain',
                          objectPosition: 'center',
                        }}
                        muted
                        playsInline
                        autoPlay
                      >
                        <source src={file?.file?.previewVideo?.url} />
                      </video>
                    )}
                    <AssetThumbnail
                      fit={
                        as === 'image'
                          ? 'cover'
                          : probablyHasAlpha
                            ? 'contain'
                            : 'contain'
                      }
                      size="small"
                      file={file}
                      w="100%"
                      h="100%"
                      pos="absolute"
                      left={0}
                      top={0}
                      right={0}
                      bottom={0}
                      m="auto"
                      style={(type) => ({
                        pointerEvents: 'none',
                        objectPosition: 'center',
                        ...(type === 'icon' || type === 'blank'
                          ? {
                              objectFit: 'contain',
                              maxWidth: '40%',
                              maxHeight: '40%',
                            }
                          : {}),
                        ...(type === 'svg'
                          ? {
                              objectFit: 'contain',
                              maxHeight: '70%',
                              maxWidth: '70%',
                            }
                          : {}),
                      })}
                    />
                  </Box>
                </>
              )}
            </Group>
            {as !== 'image' && (
              <Box
                w="100%"
                miw={1}
                style={{ overflow: 'hidden' }}
                ta="center"
                p="sm"
              >
                <PixiTitle
                  maw="100%"
                  size={13}
                  fw="400"
                  c="dimmed"
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {truncate(file.name, 50, '...')}
                </PixiTitle>
              </Box>
            )}
          </Stack>
        </>
      </Paper>
    );
  }

  if (isNative()) {
    return (
      <NativeDragWrapper
        file={file}
        fileUrl={generateUrl(file?.file?.url)}
        onLoading={setIsLoading}
      >
        {render}
      </NativeDragWrapper>
    );
  }
  return render;
};
