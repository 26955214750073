import { Anchor, Badge, Group, Stack, Switch } from '@mantine/core';
import { useAssetEditPanel } from '../../context';
import { Detail, SectionCollapse } from '../../elements';
import { openInNewTab, truncate } from 'utils';
import useStorageUrl from 'hooks/files/useStorageUrl';
import { format } from 'date-fns';
import { useUserContext } from 'hooks';
import { useState } from 'react';
import PixiButton from '@pixi/elements/Button';
import useUserStatus from 'hooks/useUserStatus';

export default function AssetEditPanelMetadata() {
  const { disabledSections, file } = useAssetEditPanel();
  const { generateUrl } = useStorageUrl();
  const [type, setType] = useState<'active' | 'inactive'>('active');
  const User = useUserContext();
  const userStatus = useUserStatus();

  if (!userStatus.activeFeatures?.attributes) {
    return <></>;
  }

  const { searchable, previewable, labels } = User.data.selectedCommunity
    ?.settings?.attributes || {
    searchable: [],
    previewable: [],
    labels: [],
  };
  function renderAttribute(key: string, value: any, cleanData?: any) {
    let valueString = '';
    try {
      valueString =
        cleanData?.type === 'number'
          ? parseInt(value).toString()
          : cleanData?.type === 'date' || cleanData?.type === 'datetime'
            ? format(
                new Date(value),
                cleanData?.type === 'date' ? 'PP' : 'PPpp',
              )
            : value?.toString();
    } catch (e) {
      valueString = value;
    }

    let valueRender = (
      <>
        {cleanData?.type === 'date' ||
        cleanData?.type === 'datetime' ||
        cleanData?.type === 'text' ||
        cleanData?.type === 'number' ? (
          valueString
        ) : cleanData?.type === 'boolean' ? (
          <>
            <Switch checked={value === 'true'} color="dark" />
          </>
        ) : (
          value
        )}
      </>
    );
    if (cleanData?.type === 'storageFiles') {
      valueRender = value?.map((file: Pickit.InterfaceStorageFile) => (
        <Anchor
          key={file.id}
          onClick={() => openInNewTab(generateUrl(file.url))}
          c="dark"
        >
          {truncate(decodeURIComponent(file.name), 50, '...')}
        </Anchor>
      ));
    }

    return (
      <Detail
        // icon={cleanData?.icon as PixiIconName}
        left={cleanData?.label || key}
        right={
          cleanData?.type === 'tags' ? (
            <Group gap="5">
              {valueString.split(',').map((keyword) => (
                <Badge key={keyword} color="dark" variant="light">
                  {keyword}
                </Badge>
              ))}
            </Group>
          ) : (
            valueRender
          )
        }
        rightProps={{
          ff: cleanData?.type === 'number' ? 'mono' : undefined,
        }}
        column={
          cleanData?.type === 'storageFiles' ? true : valueString?.length > 25
        }
      />
    );
  }

  if (!Object.keys(file.attributes || {}).length) {
    return <></>;
  }
  return (
    <>
      {!disabledSections?.includes('metadata') && (
        <SectionCollapse label="Metadata">
          <Group wrap="nowrap" gap="xs">
            <PixiButton
              fullWidth
              onClick={() => {
                setType('active');
              }}
              color={type === 'active' ? 'primary' : 'dark'}
              variant="light"
              size="xs"
            >
              Active metadata
            </PixiButton>
            <PixiButton
              fullWidth
              onClick={() => {
                setType('inactive');
              }}
              color={type === 'inactive' ? 'primary' : 'dark'}
              variant="light"
              size="xs"
            >
              Inactive metadata
            </PixiButton>
          </Group>
          <Stack>
            {Object.keys(file.attributes || {})
              .filter((k) => !!file.attributes[k])
              .filter((m) => {
                if (type === 'active') {
                  return (
                    searchable?.includes(m) ||
                    previewable?.includes(m) ||
                    labels?.includes(m)
                  );
                }
                if (type === 'inactive') {
                  return (
                    !searchable?.includes(m) &&
                    !previewable?.includes(m) &&
                    !labels?.includes(m)
                  );
                }
              })
              .map((key) => renderAttribute(key, file.attributes[key]))}
          </Stack>
        </SectionCollapse>
      )}
    </>
  );
}
