import { useState } from 'react';
import useUserStatus from 'hooks/useUserStatus';
import { useUserContext } from 'hooks';
import { useAssetActions } from '@pixi/components/AssetActions/useAssetActions';
import { AssetDropdownRender } from '@pixi/components/AssetActions';
import { format, isAfter, isBefore } from 'date-fns';
import PixiButton from '@pixi/elements/Button';
import { isAddin, isNative } from 'utils/platform';
import PixiDropdown from '@pixi/elements/Dropdown';
import PixiIcon from '@pixi/elements/Icon';
import { getDefaultAssetToolbar } from '@pixi/Vars';
import ImageEditor from 'components/ImageEditor';
import { Group, Kbd, Stack } from '@mantine/core';
import System from '@pixi/System';
import { downloadUrl } from 'utils';
import { insert } from 'services';
import { AssetPreviewProps } from '../types';
import { useConfigStoreValue } from '@pixi/store';
import { useAppContext } from 'contexts/Providers/AppProvider';
import {
  AssetActionIds,
  AssetActionOption,
} from '@pixi/components/AssetActions/Types';

export const AssetPreviewActions = ({
  file,
  disableDownload,
  view,
  onView,
  keys,
}: {
  file: Pickit.FileInterface;
  disableDownload?: boolean;
  view?: string;
  onView?: (val: string) => void;
  keys?: AssetPreviewProps['keys'];
}) => {
  const [isImageEditor, setIsImageEditor] = useState(false);
  const userStatus: any = useUserStatus();
  const User = useUserContext();
  const App = useAppContext();
  const viewport = useConfigStoreValue('APP_VIEW', 'viewport');
  const { toolbar, specificActions } = useAssetActions({
    file,
    confirmProps: {
      zIndex: 99999999,
    },
    dropdownProps: {
      zIndex: 99999999,
    },
  });

  const isMobile =
    viewport === 'xxs' ||
    viewport === 'xs' ||
    viewport === 'sm' ||
    viewport === 'md';
  const actions = toolbar.reduce(
    (
      end: Record<AssetActionIds, AssetActionOption>,
      item: AssetActionOption,
    ) => {
      end[item.id] = item;
      return end;
    },
    {} as Record<AssetActionIds, AssetActionOption>,
  );

  const licenseNotActive =
    file?.license?.startDate &&
    isAfter(new Date(file?.license?.startDate), new Date());
  const licenseExpired =
    file?.license?.expirationDate &&
    isBefore(new Date(file?.license?.expirationDate), new Date());

  let allowFeedback = false;
  const feedbackSettings = User.data?.selectedCommunity?.settings?.preferences
    ?.feedback ?? [{ library: 'media' }, { library: 'documents' }];
  const feedbackPrefs = feedbackSettings?.find(
    (prefs: { library: string }) => prefs.library === file.type,
  );

  const allowUserFeedback =
    file.file?.allowFeedback ||
    // currentCollection?.allowFileFeedback ||
    feedbackPrefs?.allowFileFeedback;

  const allowExternalUserFeedback =
    file.file?.allowFeedbackFromExternal ||
    // currentCollection?.allowFeedbackFromExternal ||
    feedbackPrefs?.allowFeedbackFromExternal;

  if (!userStatus.product.isExternalUser && allowUserFeedback) {
    allowFeedback = true;
  }
  if (userStatus.product.isExternalUser && allowExternalUserFeedback) {
    allowFeedback = true;
  }

  let mainAction = <></>;

  if (
    licenseNotActive &&
    !userStatus?.product?.isBusinessAdmin &&
    file?.license?.startDate
  ) {
    mainAction = (
      <PixiButton disabled>
        Download disabled, license is not active until{' '}
        {format(new Date(file?.license?.startDate), 'yyyy-MM-dd')}
      </PixiButton>
    );
  } else if (licenseExpired) {
    mainAction = (
      <PixiButton disabled>Download disabled, license has expired</PixiButton>
    );
  } else {
    let primaryAction: AssetActionOption | null = actions?.download;

    if (disableDownload) {
      primaryAction = null;
    }

    if (actions?.insert) {
      primaryAction = actions?.insert;
    }
    if (actions?.insert_slide) {
      primaryAction = actions?.insert_slide;
    }

    if (!primaryAction && actions?.download_external) {
      primaryAction = actions?.download_external;
    }

    if (!primaryAction && actions?.open_external) {
      primaryAction = actions?.open_external;
    }

    if (!primaryAction && actions?.open) {
      primaryAction = actions?.open;
    }

    if (isNative() && actions?.copy) {
      primaryAction = actions?.copy;
    }

    if (file?.data?.type === 'text') {
      primaryAction = actions?.favorite;
    }

    const primaryActionButton = primaryAction && (
      <PixiButton
        size={isMobile ? 'md' : 'md'}
        onClick={(event) => primaryAction?.onClick?.(event)}
        dropdown={
          primaryAction?.dropdownOptions?.length &&
          (({ isOpen }) => (
            <AssetDropdownRender
              file={file}
              isOpen={isOpen}
              action={primaryAction}
              rootzIndex={20}
              actionDropdownOnly
            />
          ))
        }
        dropdownProps={{
          position: 'top-end',
          width: 280,
          zIndex: 20,
        }}
        leftSection={
          primaryAction?.icon && <PixiIcon name={primaryAction?.icon} />
        }
      >
        {primaryAction?.label}
      </PixiButton>
    );

    if (primaryAction && primaryActionButton) {
      const actions = specificActions(
        getDefaultAssetToolbar(file, {
          exclude: [
            'preview',
            ...(!isMobile ? ['add_to_bag', 'favorite'] : []),
            ...(!App.isManage ? ['settings', 'transfers'] : []),
          ] as AssetActionIds[],
        }),
        false,
      ).filter((l) => l.id !== primaryAction?.id);
      mainAction = (
        <>
          {!!actions?.length && (
            <PixiDropdown
              width={270}
              withinPortal={false}
              position="top-end"
              target={
                <PixiButton
                  variant="light"
                  color="gray"
                  size="compact-md"
                  h="auto"
                >
                  <PixiIcon name="ellipsis" />
                </PixiButton>
              }
              customRender={({ isOpen }) => {
                return (
                  <AssetDropdownRender
                    file={file}
                    isOpen={isOpen}
                    rootzIndex={999999}
                    actions={specificActions(
                      [
                        ...getDefaultAssetToolbar(file, {
                          exclude: [
                            'preview',
                            ...(!isMobile ? ['add_to_bag', 'favorite'] : []),
                            ...(!App.isManage ? ['settings', 'transfers'] : []),
                          ] as AssetActionIds[],
                        }),
                        'image_editor',
                      ],
                      false,
                    ).filter((l) => l.id !== primaryAction?.id)}
                    dropdownProps={{
                      zIndex: 999999,
                      position: 'left-end',
                    }}
                  />
                );
              }}
            />
          )}
          {primaryAction?.customRender
            ? primaryAction.customRender(primaryActionButton, {
                isOpen: true,
                onClose: () => {},
                setIsFreezeDropdown: () => {},
              })
            : primaryActionButton}
        </>
      );
    }
  }

  const extraActions = specificActions([
    'add_to_bag',
    'favorite',
    'image_editor',
  ]);

  return (
    <>
      <ImageEditor
        preferences={{
          locale: {
            labelButtonExport: isAddin() ? 'Insert' : 'Download',
          },
        }}
        visible={isImageEditor}
        onComplete={async (newFile: File) => {
          const toBase64 = (newFile: File): Promise<string> =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(newFile);
              reader.onload = () => resolve(reader.result as string);
              reader.onerror = (error) => reject(error);
            });
          try {
            const base64 = await toBase64(newFile);
            if (!isAddin()) {
              return downloadUrl(base64, file.file?.name);
            }
            setIsImageEditor(true);
            await insert.fromMedia(
              base64.replace('data:image/png;base64,', ''),
              newFile,
            );
          } catch (e) {
            System.Report.logError(e as Error);
          }
          setIsImageEditor(false);
        }}
        onHide={() => setIsImageEditor(false)}
        src={file.file?.previews?.[0]?.url}
      />
      <>
        <Group
          align="center"
          justify="flex-end"
          style={{ width: '100%' }}
          gap="xs"
          wrap="nowrap"
        >
          {!!keys?.length && !isMobile && (
            <Group w="100%">
              <Stack style={{ marginRight: 'auto' }} gap={0}>
                <span
                  style={{
                    marginBottom: 5,
                    opacity: 0.6,
                    fontSize: 10,
                    fontWeight: 500,
                  }}
                >
                  Keyboard shortcuts
                </span>
                <Group>
                  {keys.map((key: any) => (
                    <Group align="center" key={key.char} gap={5}>
                      <Kbd>{key.char}</Kbd> {key.label}
                    </Group>
                  ))}
                </Group>
              </Stack>
            </Group>
          )}
          {!isMobile && !!extraActions?.length ? (
            <Group w="100%" gap="xs" justify="flex-end">
              {extraActions.map((a) => {
                const trigger = (
                  <PixiButton
                    size={isMobile ? 'md' : 'md'}
                    key={a.id}
                    variant="glass"
                    color={a.color || 'dark'}
                    onClick={(event) => a.onClick?.(event)}
                    leftSection={
                      a.icon && <PixiIcon name={a.icon} {...a.iconProps} />
                    }
                  >
                    {a.label}
                  </PixiButton>
                );

                if (a.customRender) {
                  return a.customRender(trigger, {
                    isOpen: true,
                    onClose: () => {},
                    setIsFreezeDropdown: () => {},
                  });
                }

                return trigger;
              })}
            </Group>
          ) : (
            <></>
          )}
          <Group gap="xs" align="stretch" style={{ flexShrink: 0 }}>
            {mainAction}
          </Group>
        </Group>
      </>
    </>
  );
};
