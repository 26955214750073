import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useUserContext } from 'hooks';
import { chunkArray, openInNewTab, randomString } from 'utils';
import { differenceInHours, isAfter } from 'date-fns';
import { getChecksum } from 'utils/checksum';
import useDataStore from 'hooks/warehouse/useStore';
import { analytics } from 'services';
import {
  BlobServiceClient,
  AnonymousCredential,
  BlockBlobClient,
} from '@azure/storage-blob';

import useUserStatus from '../hooks/useUserStatus';
import { useEventContext } from './Providers/EventProvider';
import { useAppContext } from './Providers/AppProvider';
import { getMultiStore, getStore, useDataStoreCallback } from '@pixi/store';
import { openAssetPreview } from '@pixi/AppController';
import PixiIcon from '@pixi/elements/Icon';

export function useFileProvider(props) {
  const {
    type,
    fileType,
    uploadLabel,
    fileTypesAllowed,
    mainContainerId,
    extensionsAllowed,
    libraryName,
    name,
  } = props;
  const App = useAppContext();
  const User = useUserContext();
  const [isManage, setIsManage] = React.useState(false);
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [sasToken, setSasToken] = React.useReducer((state, action) => {
    return { ...action };
  }, {});
  const [isInitialized, setIsInitialized] = React.useState(false);
  const stores = useDataStore({
    'files-count': 0,
  });
  const [isDown, setIsDown] = React.useState(false);
  const [reasonDown, setReasonDown] = React.useState(false);
  const [editingCollection, setEditingCollection] = React.useState(null);
  const [editingCollectionGroup, setEditingCollectionGroup] =
    React.useState(null);
  const [triggerUpload, setTriggerUpload] = React.useState(null);
  const [fieldCounts, setFieldCounts] = React.useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const Event = useEventContext();
  const [attributesEstimation, setAttributesEstimation] = useReducer(
    (state, action) => ({ ...action }),
    {},
  );
  const isRotatingStorageToken = useRef(false);

  const defaultFilters = [
    'uploaded_by',
    'shared_to',
    'source',
    'vision_categories',
    'vision_landmarks',
    'vision_celebrities',
    'vision_imagetype',
  ];

  async function getAttributesEstimation() {
    const estimation = await request.getAttributeEstimation();
    setAttributesEstimation(estimation);
  }

  const userStatus = useUserStatus();
  const [isLoadingFields, setIsLoadingFields] = React.useState(false);
  const [isLoadingCollections, setIsLoadingCollections] = React.useState(true);
  const [slug, setSlug] = useState(false);
  const [storageTokens, setStorageTokens] = useReducer(
    (state, action) => ({ ...action }),
    [],
  );

  // useEffect(() => {
  //   if (stores.data?.files?.length) {
  //     getStore('FILES').addOrUpdate(stores.data?.files);
  //   }
  // }, [stores.data?.files]);

  useEffect(() => {
    if (User.data?.selectedCommunityLibraries?.[type]) {
      const brandData = User.data?.selectedCommunityBrand;
      const libraryData = User.data?.selectedCommunityLibraries?.[type];
      const tokenData = User.data?.storage?.tokens?.[slug]?.[type];
      if (tokenData) {
        setSasToken(tokenData.token);
        setStorageTokens(
          Object.keys(User.data?.storage?.tokens)?.reduce((result, com) => {
            return {
              ...result,
              [com]: User.data?.storage?.tokens?.[com]?.[type]?.token,
            };
          }, {}),
        );
      }
      setIsInitialized(true);
      bindRawFilters(libraryData.filters);

      if (libraryData) {
        if (brandData?.length) {
          getStore('FILES').addOrUpdate(brandData);
        }
        getStore('COLLECTIONS').addOrUpdate(libraryData?.collections);
        stores.setData([
          ...(brandData?.length
            ? [
                {
                  type: 'merge',
                  store: 'files',
                  key: '_id',
                  data: brandData,
                },
              ]
            : []),
          {
            type: 'replace',
            store: 'files-count',
            data: parseInt(libraryData?.fileCount) || 0,
          },
          {
            type: 'merge',
            store: 'collections',
            data: libraryData?.collections || [],
            key: '_id',
          },
          // {
          //   type: 'merge',
          //   store: 'folders',
          //   data: libraryData?.folders || [],
          //   key: '_id',
          // },
          {
            type: 'replace',
            store: 'groups',
            data: libraryData?.groups || [],
          },
        ]);
      }
    }
  }, [User.data?.storage?.token, User.data?.selectedCommunity, slug]);

  const request = User.request[type];
  request.setDefaultHeader('pickit-file-service', type);

  const [globalSettings, setGlobalSettings] = React.useReducer(
    (state, action) => {
      if (!action?.data && action?.key) {
        delete state[action.key];
        return { ...state };
      }
      return {
        ...state,
        [action.key]: {
          ...action.data,
        },
      };
    },
    {},
  );

  const [globalFilesHook, setGlobalFilesHook] = React.useReducer(
    (state, action) => {
      if (!action?.data && action?.key) {
        delete state[action.key];
        return { ...state };
      }
      return {
        ...state,
        [action.key]: {
          ...action.data,
        },
      };
    },
    {},
  );

  function isFileSupported(mimetype, extension) {
    if (mimetype) {
      const parts = mimetype.split('/');
      const category = parts[0];
      const anyType = `${category}/*`;
      if (
        fileTypesAllowed.includes(anyType) ||
        fileTypesAllowed.includes(mimetype)
      ) {
        return true;
      }
    }
    if (extension) {
      if (extensionsAllowed.includes(extension.toLowerCase())) {
        return true;
      }
    }
    return false;
  }

  async function getUnprocessedFiles() {
    if (userStatus.product.isBusinessAdmin) {
      (async () => {
        const failedUploads = await request.getDocuments({
          unprocessed: 1,
        });
        stores.setData({
          type: 'replace',
          store: 'unprocessed-files',
          data: failedUploads?.unprocessed,
        });
      })();
    }
  }

  const processingFiles = useDataStoreCallback('FILES', (files) => {
    return files?.filter((file) => {
      return Object.values(file?.processing || {}).filter(
        (val) => val?.isProcessing && val?.status?.code,
      )?.length;
    });
  });

  const processingFilesRef = useRef();
  useEffect(() => {
    if (processingFiles?.length && processingFiles?.length < 100) {
      processingFilesRef.current = setTimeout(() => {
        getDocumentsFromArrayId(processingFiles.map((file) => file._id));
      }, 4000);
    }
    return () => {
      clearTimeout(processingFilesRef.current);
    };
  }, [processingFiles]);

  useEffect(() => {
    if (sasToken.token?.main) {
      parseData();
    }
  }, [sasToken.token?.main]);

  useEffect(() => {
    if (User.authenticated && User.spaces?.selected?.slug) {
      const timeout = setInterval(async () => {
        if (sasToken?.token?.main && isTokenExpired(sasToken)) {
          const sasToken = await request.getSasToken(
            User.spaces?.selected?.slug,
          );
          const tokens = await request.getSasTokens();
          setStorageTokens(tokens);
          setSasToken(sasToken);
        }
      }, 20000);

      return () => {
        clearInterval(timeout);
      };
    }
  }, [sasToken, User.authenticated]);

  useEffect(() => {
    if ((User.spaces?.selected?.slug || !slug) && User.authenticated) {
      setSlug(User.spaces?.selected?.slug);
    } else if (User.authenticated) {
      getTokens();
    }
  }, [User.spaces?.selected?.slug, User.authenticated, slug]);

  async function bindRawFilters(filters) {
    if (isLoadingFields) {
      return false;
    }
    setIsLoadingFields(true);
    let users = { data: [] };
    try {
      if (filters.uploaded_by.data?.length) {
        users = await User.request.proxy.getUsersV2({
          search: `${filters.uploaded_by.data
            .map((row) => row.value)
            .join(',')},`,
        });
      }
    } catch (e) {}
    const importedFiles = [];
    if (filters?.source?.data?.length) {
      filters.source.data.forEach((row) => {
        const data = {
          count: row.count,
          id: !row.value ? 'django-files' : row.value,
          value:
            row.value === 'django-files' || !row.value
              ? ['null', 'django-files']
              : row.value,
          name:
            row.value === 'django-files' || !row.value
              ? 'Uploaded'
              : row.value === 'pickit-market'
                ? 'Pickit Stock'
                : row.value === 'adobe-stock'
                  ? 'Adobe Stock'
                  : row.value === 'getty-images'
                    ? 'Getty Images'
                    : row.value === 'box'
                      ? 'Box'
                      : row.value === 'dropbox'
                        ? 'Dropbox'
                        : row.value === 'shutterstock'
                          ? 'Shutterstock'
                          : row.value === 'google-drive'
                            ? 'Google Drive'
                            : row.value === 'microsoft'
                              ? 'Microsoft 365'
                              : row.value === 'google-drive'
                                ? 'Google Drive'
                                : row.value === 'adobeccl'
                                  ? 'Adobe Creative Cloud'
                                  : '',
        };
        if (!data.name) {
          return false;
        }
        const alreadyFound = importedFiles.findIndex((r) => r.id === data.id);
        if (alreadyFound > -1) {
          importedFiles[alreadyFound].count += data.count;
        } else {
          importedFiles.push(data);
        }
      });
    }
    setIsLoadingFields(false);
  }

  /* Function used to get values for the filter dropdown on uploaders.
  /* More filters coming */
  async function getFilterSplits(returnOnly, prefs) {
    setIsLoadingFields(true);
    const filters = await request.getFilters(prefs);
    let users = { data: [] };
    try {
      users = await User.request.proxy.getUsersV2({
        search: `${filters.uploaded_by.data
          .map((row) => row.value)
          .join(',')},`,
      });
    } catch (e) {}
    const importedFiles = [];
    if (filters.source?.data?.length) {
      filters.source.data.forEach((row) => {
        const data = {
          count: row.count,
          id: !row.value ? 'django-files' : row.value,
          value:
            row.value === 'django-files' || !row.value
              ? ['null', 'django-files']
              : row.value,
          name:
            row.value === 'django-files' || !row.value
              ? 'Uploaded'
              : row.value === 'pickit-market'
                ? 'Pickit Stock'
                : row.value === 'adobe-stock'
                  ? 'Adobe Stock'
                  : row.value === 'getty-images'
                    ? 'Getty Images'
                    : row.value === 'box'
                      ? 'Box'
                      : row.value === 'dropbox'
                        ? 'Dropbox'
                        : row.value === 'shutterstock'
                          ? 'Shutterstock'
                          : row.value === 'google-drive'
                            ? 'Google Drive'
                            : row.value === 'microsoft'
                              ? 'Microsoft 365'
                              : row.value === 'google-drive'
                                ? 'Google Drive'
                                : row.value === 'adobeccl'
                                  ? 'Adobe Creative Cloud'
                                  : '',
        };
        if (!data.name) {
          return false;
        }
        const alreadyFound = importedFiles.findIndex((r) => r.id === data.id);
        if (alreadyFound > -1) {
          importedFiles[alreadyFound].count += data.count;
        } else {
          importedFiles.push(data);
        }
      });
    }
    if (returnOnly) {
      const results = [];
      const returnFilters = {
        ...(filters?.uploaded_by?.data?.length
          ? {
              uploaded_by: users?.data.map((user) => ({
                data: user,
                count: filters.uploaded_by?.data?.find(
                  (row) => row.value === user.id,
                )?.count,
                id: user.id,
              })),
            }
          : {}),
        sharedTo: filters.shared_to?.data?.map((row) => ({
          count: row.count,
          id: row.value.toLowerCase(),
        })),
        fileTypes: filters.type?.data?.map((row) => ({
          count: row.count,
          id: row.value.toLowerCase(),
        })),
        imported: importedFiles,
      };
      if (returnFilters?.fileTypes?.length) {
        results.push({
          view: {
            placeholder: 'Type',
            key: 'file.ext',
            icon: 'Files',
          },
          data: [
            {
              key: 'null',
              value: null,
              label: 'All',
              atTop: true,
            },
            ...(fieldCounts?.fileTypes
              ?.filter((row) => !!row.id)
              ?.map((row) => ({
                key: row.id,
                value: row.id,
                count: row.count,
                label: row.id === 'pptxslide' ? 'PowerPoint Slide' : row.id,
              })) || []),
          ],
        });
      }
      if (returnFilters?.sharedTo?.length) {
        results.push({
          view: {
            placeholder: 'Shared to',
            key: 'share_to',
            icon: 'PersonCheck',
          },
          data: [
            {
              key: 'null',
              value: null,
              label: 'All',
              atTop: true,
            },
            ...(fieldCounts?.sharedTo
              ?.filter((row) => !!row.id)
              ?.map((row) => ({
                key: row.id,
                value: row.id,
                count: row.count,
                label:
                  row.id === 'none'
                    ? 'Admins only'
                    : row.id === 'not-set'
                      ? 'Inherit'
                      : row.id === 'all'
                        ? 'All users'
                        : row.id === 'specific'
                          ? 'Specific users'
                          : row.id.toLowerCase(),
              })) || []),
          ],
        });
      }
      if (returnFilters?.imported?.length) {
        results.push({
          view: {
            placeholder: 'Source',
            key: 'import.from',
            icon: 'Cloud',
          },
          hidden: !userStatus?.product?.isBusinessAdmin,
          data: [
            {
              key: 'null',
              value: null,
              label: 'All',
              atTop: true,
            },
            ...(returnFilters?.imported?.map((row) => ({
              key: row.id,
              value: row.value,
              count: row.count,
              label: row.name,
            })) || []),
          ],
        });
      }
      if (returnFilters?.uploaded_by?.length) {
        results.push({
          view: {
            placeholder: 'Uploaded by',
            key: 'file.uploaded_by',
            icon: 'People',
          },
          hidden: !userStatus?.product?.isBusinessAdmin,
          data: [
            {
              key: 'null',
              value: null,
              label: 'All',
              atTop: true,
            },
            ...(returnFilters?.uploaded_by?.map((user) => ({
              key: user.id,
              value: user.id,
              count: user.count,
              label: user.data.first_name
                ? `${user.data.first_name} ${user.data.last_name}`
                : user.data.email?.split('@')?.[0],
            })) || []),
          ],
        });
      }
      return results;
    }
    bindRawFilters(filters);
    setIsLoadingFields(false);
  }

  function isTokenExpired(sasToken) {
    if (!sasToken?.token?.main) {
      return true;
    }
    const params = new URLSearchParams(sasToken.token?.main);
    const expiresAt = new Date(params.get('se'));
    const isExpired = isAfter(new Date(), expiresAt);
    if (isExpired) {
      return true;
    }
    return false;
  }

  const getTokens = async () => {
    if (isRotatingStorageToken?.current) {
      return false;
    }
    isRotatingStorageToken.current = true;
    const tokens = await request.getSasTokens();
    setStorageTokens(tokens);
    setSasToken(tokens[slug]);
    isRotatingStorageToken.current = false;
  };

  const getOCRContextForFile = async (documentId, fullText = false) => {
    return await request.getOCRContextForFile(documentId, fullText);
  };

  const updateOCRContextStatusForFile = async (documentId, disable = false) => {
    return await request.updateOCRContextStatusForFile(documentId, disable);
  };

  const createStorageUrl = (url) => {
    if (url) {
      const storage = url[3].split('-');
      const community = storage[0];
      const type = storage[1];
      const token = storageTokens?.[community]?.[type];
      if (!token) {
        return url;
      }
      return `${url.split('?')[0]}?${token}`;
    }
  };

  const getToken = async (sasToken = {}, force) => {
    if ((isTokenExpired(sasToken) || force) && User.spaces?.selected?.slug) {
      sasToken = await request.getSasToken(User.spaces?.selected?.slug);
      setSasToken(sasToken);
      getTokens();
    }
    return sasToken;
  };

  async function getClient(type) {
    const data = await getToken(sasToken);
    const anonymousCredential = new AnonymousCredential();
    const blobServiceClient = new BlobServiceClient(
      `${data.url}/?${data.token[type]}`,
      anonymousCredential,
    );
    const containerClient = blobServiceClient.getContainerClient(
      data.containers[type],
    );
    return containerClient;
  }

  async function getFile(type, fileId) {
    return request.getFile(type, fileId);
  }

  async function getFileBlob(type, fileId) {
    const client = await getClient(type);
    const blobClient = client.getBlockBlobClient(fileId);
    const properties = await blobClient.getProperties();
    return properties;
  }

  async function getExplore() {
    return request.getExplore();
  }

  async function getDocument(id, ignoreState) {
    const exisitingDocument = await stores.data?.files?.find(
      (file) => file._id === id,
    );
    if (!ignoreState && exisitingDocument) {
      return exisitingDocument;
    }
    const data = await request.getDocument(id);
    getStore('FILES').addOrUpdate(
      User.authenticated && sasToken.main ? parseFile([data]) : [data],
    );
    stores.setData({
      store: 'files',
      type: 'merge',
      key: '_id',
      data: User.authenticated && sasToken.main ? parseFile([data]) : [data],
    });
    return data;
  }

  function fileIsPreviewable(file, ignorePreviews, noVideo, noAudio) {
    if (file?.url) {
      return fileIsPreviewable({ file }, ignorePreviews, noVideo, noAudio);
    }
    if (!file?.file) {
      return false;
    }
    const ext = file.file?.ext || file.file?.name?.split('.')?.pop();
    return (
      (!ignorePreviews && !!file.file?.previews?.length) ||
      ext === 'jpg' ||
      ext === 'png' ||
      ext === 'gif' ||
      (ext === 'mp4' && !noVideo) ||
      (ext === 'mp3' && !noAudio) ||
      ext === 'jpeg' ||
      ext === 'webm' ||
      ext === 'svg'
    );
  }

  async function getAllFilesInCollection(collectionId) {
    const response = await request.getAllFilesInCollection(collectionId);
    getStore('FILES').addOrUpdate(response?.documents || []);
    stores.setData({
      store: 'files',
      type: 'merge',
      key: '_id',
      data: response?.documents || [],
    });
    return response;
  }

  async function getFiles(settings, skipStore, communitySlug, signal) {
    const response = await request.getDocuments(settings, {
      communitySlug,
      asPOST: true,
      signal,
    });
    if (!skipStore && !settings?.count) {
      getStore('FILES').addOrUpdate(parseFiles(response?.documents || []));
      stores.setData({
        store: 'files',
        type: 'merge',
        key: '_id',
        data: parseFiles(response?.documents || []),
      });
    }
    return response;
  }

  async function getFilesAndDownloadAsZip(settings) {
    const files = await getFiles({
      ...settings,
      limit: 999999,
    });
    const urls = files.documents
      ?.filter((f) => !!f.file?.url && !f?.file?.external_file)
      .map((f) => f.file.url);
    const res = await fetch(
      'https://pickit-url-zipper-bwbwdge2bzb9bbeu.westeurope-01.azurewebsites.net/create-download',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          urls,
        }),
      },
    );
    const data = await res.json();
    openInNewTab(data?.downloadUrl);
  }

  async function downloadFilesAsZip(files) {
    const urls = files
      ?.filter((f) => !!f.file?.url && !f?.file?.external_file)
      .map((f) => f.file.url);
    const res = await fetch(
      'https://pickit-url-zipper-bwbwdge2bzb9bbeu.westeurope-01.azurewebsites.net/create-download',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          urls,
        }),
      },
    );
    const data = await res.json();
    openInNewTab(data?.downloadUrl);
  }

  async function downloadCollectionAsZip(collection) {
    const files = await getFiles({
      filter: JSON.stringify({
        collections: collection._id,
      }),
      limit: 99999,
    });
    const urls = files.documents
      ?.filter((f) => !!f.file?.url && !f?.file?.external_file)
      .map((f) => f.file.url);
    const res = await fetch(
      'https://pickit-url-zipper-bwbwdge2bzb9bbeu.westeurope-01.azurewebsites.net/create-download',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          urls,
          name: collection.name,
        }),
      },
    );
    const data = await res.json();
    openInNewTab(data?.downloadUrl);
  }

  async function getCollections(settings) {
    const response = await request.getCollections(settings);
    return response;
  }

  async function getCollection(id) {
    const data = await request.getCollection(id);
    getStore('COLLECTIONS').addOrUpdate([data]);
    return data;
  }

  async function search(params) {
    const data = await request.search({ ...params, isManage });
    return data;
  }

  async function saveGroup(groupData) {
    try {
      const id = groupData._id || 'create';
      if (id !== 'create') {
        stores.setData({
          type: 'update',
          store: 'groups',
          data: groupData,
          key: '_id',
        });
      }
      const newData = await request.saveGroup({
        ...groupData,
        placement:
          id === 'create' ? stores.data.groups.length : groupData.placement,
      });
      if (id === 'create') {
        stores.setData({
          type: 'push',
          store: 'groups',
          data: {
            ...newData.savedGroupObject,
            documents: [],
          },
          key: '_id',
        });
      }
      return newData;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * COLLECTIONS
   */

  async function saveCollection(collectionData) {
    const id = collectionData._id || 'create';
    const newData = await request.saveCollection({
      ...collectionData,
      placement:
        id === 'create'
          ? stores.data.collections.length
          : collectionData.placement,
    });
    getStore('COLLECTIONS').addOrUpdate({
      ...newData.savedCollectionObject,
      thumbnail_media: collectionData?.thumbnail_media?._id
        ? collectionData?.thumbnail_media
        : newData.savedCollectionObject?.thumbnail_media,
      documents: [],
    });
    stores.setData({
      type: id === 'create' ? 'push' : 'update',
      store: 'collections',
      data: {
        ...newData.savedCollectionObject,
        thumbnail_media: collectionData?.thumbnail_media?._id
          ? collectionData?.thumbnail_media
          : newData.savedCollectionObject?.thumbnail_media,
        documents: [],
      },
      key: '_id',
    });
    return newData;
  }
  async function deleteGroup(group) {
    try {
      await request.deleteGroup(group._id);
      stores.setData({
        type: 'delete',
        store: 'groups',
        key: '_id',
        data: group,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function deleteCollection(collection) {
    try {
      // await ExternalSystems.Dropbox.disconnectFolder(collection._id);
      const data = await request.deleteCollection(collection._id);
      if (data?.affectedDocuments?.length) {
        getStore('FILES').addOrUpdate(data.affectedDocuments);
        stores.setData(
          data?.affectedDocuments.map((doc) => {
            return {
              type: 'update',
              store: 'files',
              key: '_id',
              data: doc,
            };
          }),
        );
      }
      getStore('COLLECTIONS').remove(collection);
      stores.setData({
        type: 'delete',
        store: 'collections',
        key: '_id',
        data: collection,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function sortGroups(groups) {
    const collectionGroups = groups
      .map((group, index) => {
        if (group?.type === 'folder') {
          return null;
        }
        return {
          placement: index,
          _id: group._id,
        };
      })
      .filter((a) => !!a?._id);
    const folders = [];
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (group?.type !== 'folder') {
        continue;
      }
      group.publish.libraries[type] = {
        ...group.publish.libraries[type],
        placement: i,
      };
      await User.request.files.folders.saveFolderItem({
        _id: group._id,
        [`publish.libraries.${type}`]: group.publish.libraries[type],
      });
      folders.push(group);
    }
    stores.setData({
      type: 'update',
      store: 'folders',
      data: folders,
      key: '_id',
    });
    stores.setData({
      type: 'replace',
      store: 'groups',
      data: groups
        .map((g, i) => {
          g.placement = i;
          return g;
        })
        .filter((g) => g.type !== 'folder'),
      key: '_id',
    });
    const newGroups = await request.sortGroups(collectionGroups);
    return newGroups;
  }

  async function sortCollections(collections) {
    const newCollectionOrder = collections
      .map((group, index) => {
        if (group?.type === 'folder') {
          return null;
        }
        return {
          placement: index,
          _id: group._id,
        };
      })
      .filter((a) => !!a?._id);
    const folders = [];
    for (let i = 0; i < collections.length; i++) {
      const item = collections[i];
      if (item?.type !== 'folder') {
        continue;
      }
      item.publish.libraries[type] = {
        ...item.publish.libraries[type],
        placement: i,
      };
      await User.request.files.folders.saveFolderItem({
        _id: item._id,
        [`publish.libraries.${type}`]: item.publish.libraries[type],
      });
      folders.push(item);
    }
    stores.setData({
      type: 'update',
      store: 'folders',
      data: folders,
      key: '_id',
    });
    getStore('COLLECTIONS').addOrUpdate(
      collections
        .map((g, i) => {
          g.placement = i;
          return g;
        })
        .filter((g) => g.type !== 'folder'),
    );
    stores.setData({
      type: 'update',
      store: 'collections',
      data: collections
        .map((g, i) => {
          g.placement = i;
          return g;
        })
        .filter((g) => g.type !== 'folder'),
      key: '_id',
    });
    const newCollections = await request.sortCollections(newCollectionOrder);
    return newCollections;
  }

  async function reParseFile(id, preferences) {
    try {
      const { data } = await request.parseFile(id, preferences);
      if (id !== 'create') {
        getStore('FILES').addOrUpdate(data);
        stores.setData({
          store: 'files',
          type: 'update',
          key: '_id',
          data,
        });
      }
      return data;
    } catch (e) {
      const data = await e.json();
      if (data) {
        throw data;
      }
      throw e;
    }
  }

  async function savePartial(fileData, advancedFind) {
    const data = await request.savePartial(fileData, advancedFind);
    getStore('FILES').addOrUpdate(JSON.parse(JSON.stringify(data)));
    stores.setData({
      store: 'files',
      type: 'update',
      key: '_id',
      data,
    });
    stores.setData({
      type: 'prepend',
      store: 'updated-files',
      key: '_id',
      data,
    });
    return data;
  }

  async function saveDocument(documentData, options) {
    const id = documentData._id || 'create';
    if (id !== 'create') {
      getStore('FILES').addOrUpdate(documentData);
      stores.setData({
        store: 'files',
        type: 'update',
        key: '_id',
        data: documentData,
      });
    }
    const data = await request.saveDocument(
      {
        ...documentData,
        thumbnail_media: documentData?.thumbnail_media?._id
          ? documentData?.thumbnail_media._id
          : documentData?.thumbnail_media,
      },
      options,
    );
    if (id === 'create') {
      getMultiStore('FILES_STATUS', 'created').addOrUpdate(data);
      stores.setData({
        type: 'replace',
        store: 'files-count',
        data: stores.data['files-count'] + 1,
      });
      if (documentData?.file?.id) {
        stores.setData({
          store: 'processing-files',
          type: 'update',
          key: 'id',
          data: documentData.file.id,
        });
      } else {
        getStore('FILES').addOrUpdate(data);
        stores.setData({
          type: 'prepend',
          store: 'files',
          key: '_id',
          data,
        });
        stores.setData({
          type: 'prepend',
          store: 'uploaded-files',
          key: '_id',
          data,
        });
      }
    }
    if (id !== 'create') {
      getStore('FILES').addOrUpdate(data);
      stores.setData({
        store: 'files',
        type: 'update',
        key: '_id',
        data,
      });
      stores.setData({
        type: 'prepend',
        store: 'updated-files',
        key: '_id',
        data,
      });
    }
    return data;
  }

  async function saveDocuments(documents, data) {
    const modifiedDocuments = await request.saveDocuments(documents, data);
    getStore('FILES').addOrUpdate(modifiedDocuments);
    stores.setData(
      modifiedDocuments.map((document) => ({
        type: 'update',
        store: 'files',
        key: '_id',
        data: document,
      })),
    );
    return modifiedDocuments;
  }

  async function saveDocumentsWhere(where, set) {
    const modifiedDocuments = await request.saveDocumentsWhere(where, set);
    getStore('FILES').addOrUpdate(modifiedDocuments.documents);
    stores.setData(
      modifiedDocuments.documents.map((document) => ({
        type: 'update',
        store: 'files',
        key: '_id',
        data: document,
      })),
    );
    return modifiedDocuments;
  }
  async function saveDocumentFeedback(document, collection, data) {
    const modifiedDocument = await request.saveDocumentFeedback(
      document,
      collection,
      data,
    );
    getStore('FILES').addOrUpdate(modifiedDocument);
    stores.setData(
      [modifiedDocument].map((document) => ({
        type: 'update',
        store: 'files',
        key: '_id',
        data: document,
      })),
    );
    return modifiedDocument;
  }

  async function saveDocumentFeedbackViewedStatus(documentIds, viewed) {
    const modifiedDocuments = await request.saveDocumentFeedbackViewedStatus(
      documentIds,
      viewed,
    );
    getStore('FILES').addOrUpdate(modifiedDocuments);
    stores.setData(
      modifiedDocuments.map((document) => ({
        type: 'update',
        store: 'files',
        key: '_id',
        data: document,
      })),
    );
    return modifiedDocuments;
  }
  async function removeDocumentFeedback(documentIds, userId) {
    const modifiedDocuments = await request.removeDocumentFeedback(
      documentIds,
      userId,
    );
    getStore('FILES').addOrUpdate(modifiedDocuments);
    stores.setData(
      modifiedDocuments.map((document) => ({
        type: 'update',
        store: 'files',
        key: '_id',
        data: document,
      })),
    );
    return modifiedDocuments;
  }
  async function alreadyUploaded(checksum) {
    const results = [];
    await Promise.all(
      chunkArray(checksum, 50).map(async (checksum) => {
        const files = await request.getDocuments(
          {
            filter: JSON.stringify({
              'file.checksum': checksum,
            }),
            limit: 50,
          },
          {
            asPOST: true,
          },
        );
        results.push(...files.documents);
      }),
    );
    const processingFiles = stores.data?.['processing-files']?.filter(
      (file) => {
        if (Array.isArray(checksum)) {
          return checksum.includes(file?.file?.checksum);
        }
        return file?.file?.checksum === checksum;
      },
    );

    if (Array.isArray(checksum)) {
      return [...(processingFiles || []), ...results];
    }

    return !!(results?.length || processingFiles?.length);
  }

  async function getUploadFileByChecksum(file) {
    const fileChecksum = await getChecksum(file);
    const uploadedFile = await request.getDocuments({
      filter: JSON.stringify({
        'file.checksum': fileChecksum,
      }),
      limit: 1,
    });
    if (uploadedFile?.documents?.length) {
      return uploadedFile?.documents?.[0] || false;
    }
    return false;
  }

  async function filterFilesAlreadyUploaded(files, onError) {
    if (!files.length) {
      return [];
    }
    const checksums = [];
    const filesChecksum = {};
    const skippedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 300000000) {
        skippedFiles.push(file);
        continue;
      }
      try {
        const fileChecksum = await getChecksum(file);
        if (fileChecksum && fileChecksum.length === 32) {
          checksums.push(fileChecksum);
          filesChecksum[fileChecksum] = file;
        }
      } catch (e) {}
    }
    const docs = await alreadyUploaded(checksums);
    docs.forEach((file) => {
      if (!onError) {
        getStore('SYSTEM_ERRORS').addOrUpdate({
          id: file?.file?.checksum,
          title: decodeURIComponent(filesChecksum[file?.file?.checksum]?.name),
          icon: 'file-exclamation',
          message: 'The file you tried to upload already exists.',
          // customAction: (setSelectedFiles) =>
          //   setSelectedFiles({ action: "replace", data: [file] }),
          // customActionText: "Edit file",
          actions: [
            {
              label: 'Show uploaded file',
              id: 'preview_file',
              variant: 'light',
              leftSection: <PixiIcon name="eye" />,
              onClick: () => {
                openAssetPreview(file);
              },
            },
          ],
          onClose: () => {
            getStore('SYSTEM_ERRORS').removeByKey(file?.file?.checksum);
          },
        });
      } else {
        onError(file, filesChecksum[file.file.checksum]);
      }
    });
    const newFiles = Object.keys(filesChecksum)
      .filter((checksum) => !docs.find((doc) => doc.file.checksum === checksum))
      .map((checksum) => {
        filesChecksum[checksum].checksum = checksum;
        return filesChecksum[checksum];
      });
    skippedFiles.forEach((file) => {
      newFiles.push(file);
    });
    return newFiles;
  }

  function filterFileType(
    files,
    fileTypes = fileTypesAllowed,
    extensions = extensionsAllowed,
    noStore = false,
  ) {
    return files.filter((file) => {
      const noMatchExtension = !extensions.includes(
        file.name.split('.').pop()?.toLowerCase(),
      );
      const noMatchFileType =
        !fileTypes.includes(file.type) &&
        !fileTypes.find((type) => {
          const parts = type.split('/');
          const fileParts = file.type.split('/');
          if (parts.pop() === '*' && parts[0] === fileParts[0]) {
            return type;
          }
          return false;
        });
      if ((!file.type && noMatchExtension) || (file.type && noMatchFileType)) {
        if (!noStore) {
          const errorId = randomString(8);
          getStore('SYSTEM_ERRORS').addOrUpdate({
            id: errorId,
            title: file.name,
            message: 'File type is not supported.',
            onClose: () => {
              getStore('SYSTEM_ERRORS').removeByKey(errorId);
            },
          });
          analytics.trackEvent('CAT File Upload Error', {
            'CAT File Service': type,
            'CAT File Upload Error': `File type "${file.name}" is not supported.`,
          });
        }
        return false;
      }
      return true;
    });
  }

  async function renameFile(fileId, name, storage) {
    const client = await getClient(storage);
    const blobClient = client.getBlockBlobClient(fileId);
    blobClient.setHTTPHeaders({
      blobContentDisposition: `attachment; filename="${encodeURIComponent(
        name,
      )}"`,
    });
  }

  async function uploadFile(file, type, observers, options, params) {
    try {
      const client = await getClient(type);
      const fileId = file.id || randomString(8);
      const blobClient = client.getBlockBlobClient(fileId);
      stores.setData({
        store: 'uploading-files',
        type: 'push',
        key: 'id',
        data: file,
      });
      await blobClient.uploadBrowserData(file, {
        onProgress: observers.onProgress,
        blobHTTPHeaders: {
          blobContentType: file.type,
          blobContentDisposition: `attachment; filename="${encodeURIComponent(
            file.name,
          )}"`,
        },
        abortSignal: observers.abort.signal,
        metadata: {
          name: encodeURIComponent(file.name),
          uploaded_by: User.djangoProfile?.id.toString(),
          uploaded_at: new Date().toISOString(),
          ...(options?.document_id
            ? { document_id: options?.document_id }
            : {}),
        },
        concurrency: 20,
      });
      const uploadedFile = await getFile(type, fileId);
      uploadedFile.checksum = file?.checksum;
      observers.onFinish(uploadedFile);
      stores.setData({
        store: 'uploading-files',
        type: 'delete',
        key: 'id',
        data: { id: file.id },
      });
      if (type === mainContainerId && !options?.returnResults) {
        setTimeout(() => {
          getUploadedDocument(uploadedFile, options, params);
        }, 50);
      }
      return uploadedFile;
    } catch (e) {
      console.error(e);
      observers.onError(e);
      observers.abort.abort();
      throw e;
    }
  }

  async function getUploadedDocument(uploadedFile, options, params) {
    try {
      stores.setData({
        type: 'delete',
        store: 'processing-errors',
        key: 'id',
        data: {
          id: uploadedFile.id,
        },
      });
      stores.setData({
        type: 'push',
        store: 'processing-files',
        key: 'id',
        data: { id: uploadedFile.id },
      });
      const doc = await saveDocument(
        {
          name: decodeURIComponent(uploadedFile.metadata.name),
          file: {
            id: uploadedFile.id,
            url: uploadedFile.url.split('?')[0],
            name: uploadedFile.metadata.name,
            checksum: uploadedFile.checksum,
          },
          ...params,
        },
        { ...options },
      );
      stores.setData({
        type: 'delete',
        store: 'processing-files',
        key: 'id',
        data: { id: doc.file.id },
      });
      stores.setData({
        type: 'delete',
        store: 'unprocessed-files',
        key: 'id',
        data: { id: doc.file.id },
      });
      stores.setData({
        type: 'prepend',
        store: 'uploaded-files',
        data: doc,
      });
      stores.setData({
        type: 'replace',
        store: 'files-count',
        data: stores.data['files-count'] + 1,
      });
      if (options.returnStore && doc) {
        getStore('FILES').addOrUpdate(doc);
        return {
          type: 'prepend',
          store: 'files',
          key: '_id',
          data: doc,
        };
      }
      getStore('FILES').addOrUpdate(doc);
      stores.setData({
        type: 'prepend',
        store: 'files',
        key: '_id',
        data: doc,
      });
      analytics.trackEvent('CAT File Uploaded', {
        'CAT File Service': type,
      });
    } catch (e) {
      if (params?.throwError) {
        throw e;
      }
      stores.setData({
        type: 'delete',
        store: 'processing-files',
        data: { id: uploadedFile.id },
      });
      if (e?.error === 'ALREADY_UPLOADED') {
        getStore('SYSTEM_ERRORS').addOrUpdate({
          id: uploadedFile.id,
          title: decodeURIComponent(uploadedFile.metadata.name),
          icon: 'warning',
          message: 'The file you tried to upload already exists.',
          onClose: () => {
            getStore('SYSTEM_ERRORS').removeByKey(uploadedFile.id);
          },
        });
        analytics.trackEvent('CAT File Upload Error', {
          'CAT File Service': type,
          'CAT File Upload Error': 'Already uploaded',
        });
      } else {
        getStore('SYSTEM_ERRORS').addOrUpdate({
          id: uploadedFile.id,
          title: decodeURIComponent(uploadedFile.metadata.name),
          message:
            'File failed to upload. The file could either be corrupt or we had a server error. Please try again or contact support.',
          tryAgain: () => getUploadedDocument(uploadedFile, options, params),
          onClose: () => {
            getStore('SYSTEM_ERRORS').removeByKey(uploadedFile.id);
          },
        });
        analytics.trackEvent('CAT File Upload Error', {
          'CAT File Service': type,
          'CAT File Upload Error': 'Unknown error',
        });
        return false;
      }
    }
  }

  function getDownloadUrl(doc, anon) {
    if (!anon) {
      return doc?.file?.download_url;
    }
    return doc?.file?.download_url_anon;
  }

  async function getDownloadBase64(fileId) {
    const client = await getClient(mainContainerId);
    const blobClient = client.getBlockBlobClient(fileId);
    const download = await blobClient.download(0);
    const blob = await download.blobBody;
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = function (event) {
        const startIndex = event.target.result.indexOf('base64,');
        const copyBase64 = event.target.result.substr(startIndex + 7);
        resolve(copyBase64); // event.target.results contains the base64 code to create the image.
      };
      reader.readAsDataURL(blob);
    });
  }

  async function finishNewVersion(file, approval) {
    file = await getDocument(file._id);
    let version = file.file.version || 1;
    const previews = [...(file.file?.previews || [])];
    version += 1;
    const uploadedFile = await getFile('main', file.file.id);
    const newFile = {
      ...file.file,
      ...uploadedFile,
      previews: previews?.length ? previews : [],
      variants: file.file?.variants,
      uploaded_at: uploadedFile?.metadata?.uploaded_at,
      uploaded_by: User.djangoProfile?.id.toString(),
      version,
      slides_published: file.file?.slides_published,
    };
    const newDoc = {
      _id: file._id,
      file: newFile,
    };
    if (approval) {
      newDoc.approval = {
        status: approval.status,
        status_change_reason: approval.reason,
      };
    }
    await saveDocument(newDoc);
  }

  async function uploadNewFileById(fileId, containerId, blob) {
    const client = await getClient(containerId);
    const blobClient = client.getBlockBlobClient(fileId);
    try {
      await blobClient.createSnapshot();
    } catch (e) {}
    await blobClient.uploadData(blob);
    const file = await getFile(containerId, fileId);
    return file;
  }

  async function uploadFileVersion(document, file, type, observers, approval) {
    try {
      const originalDocument = JSON.parse(JSON.stringify(document));
      const client = await getClient(type);
      let blobClient = client.getBlockBlobClient(document.file.id);
      let version = document.file.version || 1;
      const previews = [...(document.file?.previews || [])];
      const { allowEmbedCode } = document.file;
      version += 1;
      const prevExt = document.file.ext;
      try {
        document.file = await getFile(type, document?.file?.id);
        await blobClient.createSnapshot();
      } catch (e) {
        document.file.id = randomString(8);
        blobClient = client.getBlockBlobClient(document.file.id);
      }
      const checksum = await getChecksum(file);
      await blobClient.uploadBrowserData(file, {
        onProgress: observers.onProgress,
        blobHTTPHeaders: {
          blobContentType: file.type,
          blobContentDisposition: `attachment; filename="${encodeURIComponent(
            file.name,
          )}"`,
        },
        abortSignal: observers.abort.signal,
        metadata: {
          name: encodeURIComponent(file.name),
          uploaded_by: User.djangoProfile?.id.toString(),
          uploaded_at: new Date().toISOString(),
        },
        concurrency: 20,
      });
      await blobClient.setHTTPHeaders({
        blobContentType: file.type,
        blobContentDisposition: `attachment; filename="${encodeURIComponent(
          file.name,
        )}"`,
      });
      const uploadedFile = await getFile(type, document.file.id);
      observers.onFinish({ ...uploadedFile, name: document.file.id });
      document.file = {
        ...uploadedFile,
        ext:
          prevExt === 'pptxslide' && uploadedFile.ext === 'pptx'
            ? 'pptxslide'
            : uploadedFile.ext,
        previews: previews?.length ? previews : [],
        variants: document.file?.variants,
        uploaded_at: uploadedFile?.metadata?.uploaded_at,
        uploaded_by: User.djangoProfile?.id.toString(),
        version,
        slides_published: originalDocument?.file?.slides_published,
        allowEmbedCode,
      };
      const newDoc = {
        _id: document._id,
        file: {
          ...document.file,
          checksum,
        },
      };
      if (approval) {
        newDoc.approval = {
          status: approval.status,
          status_change_reason: approval.reason,
        };
      }
      await saveDocument(
        newDoc,
        approval ? { need_approval: true } : undefined,
      );
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteDocument(document) {
    await deleteFiles([document?._id]);
  }

  // TODO: Add logic to use different function depending on if one or more files are being passed in arg.

  async function moveDocumentToTrash(document) {
    await savePartial({
      _id: document?._id,
      trash: {
        isTrash: true,
      },
    });
    getStore('FILES').remove([document]);
    stores.setData(deleteFilesFromState([document]));
  }

  async function moveDocumentsToTrash(documents) {
    await saveDocumentsWhere(
      {
        _id: { $in: documents.map((file) => file._id) },
      },
      {
        trash: {
          isTrash: true,
        },
      },
    );
    getStore('FILES').remove(documents);
    stores.setData(deleteFilesFromState(documents));
  }

  async function recoverDocumentsFromTrash(documents) {
    await saveDocumentsWhere(
      {
        _id: { $in: documents.map((file) => file._id) },
      },
      {
        trash: {
          isTrash: false,
        },
      },
    );
    getStore('FILES').remove(documents);
    stores.setData(deleteFilesFromState(documents));
  }

  async function fileIsInState(fileId) {
    return (
      stores.data?.['processing-files']?.find((f) => f._id == fileId) ||
      stores.data?.['uploaded-files']?.find((f) => f._id === fileId) ||
      stores.data?.files?.find((f) => f._id === fileId)
    );
  }

  function deleteFilesFromState(files) {
    getStore('FILES').remove(files.map((f) => ({ _id: f })));
    const newStore = (files || []).reduce((updateObj, document) => {
      return [
        ...updateObj,
        {
          type: 'delete',
          store: 'unprocessed-files',
          key: 'id',
          data: { id: document },
        },
        {
          type: 'delete',
          store: 'upload-errors',
          key: 'id',
          data: { id: document },
        },
        {
          type: 'delete',
          store: 'processing-errors',
          key: 'id',
          data: { id: document },
        },
        {
          type: 'delete',
          store: 'processing-files',
          key: '_id',
          data: { _id: document },
        },
        {
          type: 'delete',
          store: 'uploaded-files',
          key: '_id',
          data: { _id: document },
        },
        {
          type: 'delete',
          store: 'files',
          key: '_id',
          data: { _id: document },
        },
        {
          type: 'replace',
          store: 'files-count',
          data: stores.data['files-count'] - 1,
        },
        {
          type: 'push',
          store: 'deleted-files',
          data: document,
        },
      ];
    }, []);
    return newStore;
  }

  async function deleteFiles(documents, onlyStore) {
    try {
      if (!onlyStore) {
        await request.deleteDocuments(documents);
      }
      getStore('FILES').remove(documents);
      stores.setData(deleteFilesFromState(documents));
    } catch (e) {}
    return [];
  }

  async function deleteFile(fileId, type) {
    try {
      const client = await getClient(type);
      const blobClient = client.getBlockBlobClient(fileId);
      await blobClient.delete({
        deleteSnapshots: 'include',
      });
      stores.setData({
        type: 'delete',
        store: 'unprocessed-files',
        key: 'id',
        data: { id: fileId },
      });
      stores.setData({
        type: 'delete',
        store: 'upload-errors',
        key: 'id',
        data: { id: fileId },
      });
      stores.setData({
        type: 'delete',
        store: 'processing-errors',
        key: 'id',
        data: { id: fileId },
      });
    } catch (e) {
      console.error(e);
    }
  }
  /** Remove files from front-end store. use deleteFiles for deleting files */
  async function removeFiles(documents) {
    try {
      stores.setData(
        documents.reduce((updateObj, document) => {
          return [
            ...updateObj,
            {
              type: 'delete',
              store: 'uploaded-files',
              key: '_id',
              data: { _id: document },
            },
            {
              type: 'delete',
              store: 'files',
              key: '_id',
              data: { _id: document },
            },
            {
              type: 'replace',
              store: 'files-count',
              data: stores.data['files-count'] - 1,
            },
            {
              type: 'push',
              store: 'deleted-files',
              data: document,
            },
          ];
        }, []),
      );
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Favorite collection — Will unfavorite if already favorited.
   * @param {string} collectionId
   */
  async function favoriteCollection(collectionId) {
    let favorites = User.cloudStorage.saved[type].collections;
    if (!Array.isArray(favorites)) {
      favorites = [];
    }
    if (favorites.includes(collectionId)) {
      favorites.splice(favorites.indexOf(collectionId), 1);
    } else {
      favorites.push(collectionId);
    }
    await User.setCloudStorage(`saved.${type}.collections`, favorites);
  }

  /**
   * Favorite document — Will unfavorite if already favorited.
   * @param {string} collectionId
   */
  async function favoriteDocument(documentId) {
    let favorites = User.cloudStorage.saved[type].files;
    if (!Array.isArray(favorites)) {
      favorites = [];
    }
    if (favorites.includes(documentId)) {
      favorites.splice(favorites.indexOf(documentId), 1);
    } else {
      favorites.push(documentId);
    }
    await User.setCloudStorage(`saved.${type}.files`, favorites);
  }

  async function getDocumentsFromArrayId(documentIds, prefs) {
    const files = await User.request[type].getDocumentsFromArrayId(
      documentIds,
      prefs,
    );
    if (files?.length) {
      getStore('FILES').addOrUpdate(files);
      stores.setData({
        store: `files`,
        type: 'merge',
        key: '_id',
        data: files,
      });
    }
    return files;
  }
  async function getCollectionsFromArrayId(collectionIds) {
    const collections =
      await User.request[type].getCollectionsFromArrayId(collectionIds);
    getStore('COLLECTIONS').addOrUpdate(collections);
    stores.setData({
      store: `collections`,
      type: 'merge',
      key: '_id',
      data: collections,
    });
    return collections;
  }

  function isDocumentFavorite(documentId) {
    return User.cloudStorage.saved[type].files.includes(documentId);
  }
  function isCollectionFavorite(collectionId) {
    return User.cloudStorage.saved[type].collections.includes(collectionId);
  }

  async function replaceMedia(url, id, from, hash, params) {
    const file = await request.replaceMedia(url, id, hash, params);
    stores.setData({
      type: 'prepend',
      store: 'uploaded-files',
      data: file,
    });
    stores.setData({
      store: `imported_refs_${from}`,
      type: 'update',
      key: '_id',
      data: {
        ...file,
      },
    });
    getStore('FILES').addOrUpdate(file);
    stores.setData({
      store: `files`,
      type: 'push',
      key: '_id',
      data: file,
    });

    return file;
  }

  async function inspectionTool(file, prefs = {}, params = {}) {
    return await request.inspectionToolSocket(
      file,
      {
        onProcess: prefs.onProcess,
        onClose: prefs.onClose,
      },
      params,
    );
  }

  async function importMedia(url, metadata, license, prefs = {}, params) {
    let file;
    if (license) {
      metadata = {
        ...metadata,
        license,
      };
    }
    if (prefs.useSocket) {
      file = await request.importFileSocket(
        type,
        url,
        metadata,
        {
          onProcess: prefs.onProcess,
          onClose: prefs.onClose,
        },
        params,
      );
    } else {
      file = await request.importMedia(url, metadata, params);
    }
    Event.dispatch(`${type}_imported_file`, file);
    if (!prefs?.skipStore) {
      stores.setData({
        type: 'prepend',
        store: 'uploaded-files',
        data: file,
      });
      if (metadata?.import?.from) {
        stores.setData({
          store: `imported_refs_${metadata?.import.from}`,
          type: 'push',
          key: '_id',
          data: file,
        });
      }
      getStore('FILES').addOrUpdate(file);
      stores.setData({
        store: `files`,
        type: 'push',
        key: '_id',
        data: file,
      });
      stores.setData({
        store: `files-count`,
        type: 'replace',
        key: '_id',
        data: stores.data['files-count'] + 1,
      });
    }

    return file;
  }

  function getDocumentSharing(doc) {
    return doc.share_to;
  }

  async function importGetFiles(from) {
    const files = await request.importGetFiles(from);
    getStore('FILES').addOrUpdate(files);
    stores.setData({
      store: `files`,
      type: 'merge',
      key: '_id',
      data: files,
    });
    stores.setData({
      store: `imported_refs_${from}`,
      type: 'replace',
      key: '_id',
      data: files.map((file) => ({
        _id: file._id,
        collections: file.collections,
        ref: file?.import?.reference,
        ...file,
      })),
    });
    return files;
  }

  async function importCheckReferences(type, ref, skipStore, checksums) {
    const refs = await request.importCheckReferences(ref, type, checksums);
    if (!skipStore) {
      stores.setData({
        store: `imported_refs_${type}`,
        type: 'merge',
        key: '_id',
        data: refs,
      });
      getStore('FILES').addOrUpdate(
        refs.map((file) => {
          delete file.ref;
          return file;
        }),
      );
      stores.setData({
        store: `files`,
        type: 'merge',
        key: '_id',
        data: refs.map((file) => {
          delete file.ref;
          return file;
        }),
      });
    }
    return refs;
  }

  async function documentToText(type, file) {
    const client = await getClient(type);
    const blobClient = client.getBlockBlobClient(file.id);
    const download = await blobClient.download(0);
    const blob = await download.blobBody;
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.readAsText(blob);
      reader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
    });
  }
  async function fileUrlToText(file) {
    const blobClient = new BlockBlobClient(file.url);
    const download = await blobClient.download(0);
    const blob = await download.blobBody;
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.readAsText(blob);
      reader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
    });
  }

  async function fileUrlToBase64(url, raw, useRemote) {
    if (useRemote) {
      const buffer = await request.getBufferFromURL(url);
      return buffer;
    }
    const blobClient = new BlockBlobClient(url);

    const download = await blobClient.download(0, undefined);
    const blob = await download.blobBody;
    const reader = new FileReader();
    return new Promise((resolve) => {
      try {
        reader.onload = function (event) {
          if (raw) {
            return resolve(event.target.result);
          }
          const startIndex = event.target.result.indexOf('base64,');
          const copyBase64 = event.target.result.substr(startIndex + 7);
          resolve(copyBase64); // event.target.results contains the base64 code to create the image.
        };
        reader.readAsDataURL(blob);
      } catch (e) {
        console.error(e.message);
      }
    });
  }
  async function documentToBase64(type, file) {
    const client = await getClient(type);
    const blobClient = client.getBlockBlobClient(file.id);
    const download = await blobClient.download(0);
    const blob = await download.blobBody;
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = function (event) {
        const startIndex = event.target.result.indexOf('base64,');
        const copyBase64 = event.target.result.substr(startIndex + 7);
        resolve(copyBase64); // event.target.results contains the base64 code to create the image.
      };
      reader.readAsDataURL(blob);
    });
  }

  function collectionIsInGroup(collection) {
    let found = false;
    if (stores.data.groups?.length) {
      stores.data.groups.forEach((group) => {
        if (group.collections?.includes(collection._id)) {
          found = true;
        }
      });
    }
    return found;
  }

  function getThumbnailUrl(file, isReturnObject, options) {
    let returnFile;
    if (
      !options?.onlyImage &&
      (file?.contentType === 'image/gif' ||
        file?.ext === 'mp4' ||
        file?.contentType?.includes('audio'))
    ) {
      returnFile = {
        ...file,
        width: file.image_details?.width,
        height: file.image_details?.height,
      };
    } else {
      const largeThumb = file?.previews?.find(
        (thumb) => thumb.name === 'large',
      );
      const mediumThumb = file?.previews?.find(
        (thumb) => thumb.name === 'medium',
      );
      const smallThumb = file?.previews?.find(
        (thumb) => thumb.name === 'small',
      );
      const tinyThumb = file?.previews?.find((thumb) => thumb.name === 'tiny');

      if (largeThumb && options?.preferLarge) {
        returnFile = largeThumb;
      } else if (mediumThumb) {
        returnFile = mediumThumb;
      } else if (smallThumb) {
        returnFile = smallThumb;
      } else if (tinyThumb) {
        returnFile = tinyThumb;
      }

      if (file?.previews?.length && !returnFile) {
        returnFile = file?.previews[0];
      }

      if (!returnFile && fileIsPreviewable(file)) {
        returnFile = file.file;
      }

      if (returnFile?.contentLength > 2500000) {
        return isReturnObject ? {} : null;
      }

      if (!isReturnObject) {
        return returnFile?.url;
      }

      return returnFile || {};
    }

    if (isReturnObject) {
      return returnFile || file;
    }
    return returnFile?.url || file?.url;
  }

  function getThumbnail(image, props, options) {
    const file = getThumbnailUrl(
      image?.file ? image.file : image,
      true,
      options,
    );
    const properties = {
      data: image,
      ...props,
      ...file,
    };
    if (!image?.file?.image_details || type === 'documents') {
      return {
        width: 200,
        height: 200,
        ...properties,
      };
    }
    return {
      width: file.width,
      height: file.height,
      src: file.url,
      ...properties,
    };
  }

  function getCollectionThumbnailUrl(collection, size = 'small') {
    if (
      collection?.thumbnail_media?.file &&
      collection?.thumbnail_media?.file?.previews?.find(
        (preview) => preview.name === size,
      )
    ) {
      return collection?.thumbnail_media?.file?.previews?.find(
        (preview) => preview.name === size,
      )?.url;
    }
    return collection?.thumbnail?.url;
  }

  function parseCollection(collection) {
    if (!sasToken.token?.thumbnails) {
      return collection;
    }
    if (collection?.thumbnail?.url) {
      collection.thumbnail = {
        ...collection.thumbnail,
        url: `${collection.thumbnail.url.split('?')[0]}?${
          sasToken.token.thumbnails
        }`,
      };
    }
    if (collection?.thumbnail_original?.url) {
      collection.thumbnail_original = {
        ...collection.thumbnail_original,
        url: `${collection.thumbnail_original.url.split('?')[0]}?${
          sasToken.token.thumbnails
        }`,
      };
    }
    if (collection?.thumbnail_media?.url) {
      collection.thumbnail_media = parseFile(collection.thumbnail_media);
    }
    collection.libraryType = type;
    return collection;
  }

  function parseFile(file) {
    if (!sasToken.token?.main) {
      return file;
    }
    const processing = Object.values(file.processing || {}).filter(
      (val) => val?.isProcessing,
    );
    if (
      processing?.length &&
      differenceInHours(new Date(), new Date(file?.updatedAt)) > 3
    ) {
      Object.keys(file.processing).map((key) => {
        if (file.processing[key].isProcessing) {
          file.processing[key].isProcessing = false;
        }
      });
    }
    function generateUrl(url) {
      try {
        if (!url) {
          return url;
        }
        const parts = url.split('/');
        if (!parts?.[3]) {
          return url;
        }
        const storage = parts?.[3]?.split('-');
        const community = storage[0];
        const type = storage[1];
        const token = storageTokens?.[community]?.[type];
        if (!token) {
          return url;
        }
        return `${url.split('?')[0]}?${token}`;
      } catch (e) {
        return url;
      }
    }
    return {
      ...file,
      file: {
        ...file.file,
        url: file.file?.external_file
          ? file.file.url
          : generateUrl(file?.file?.url),
        previews: file.file?.previews
          ? file.file.previews.map((preview) => ({
              ...preview,
              url: generateUrl(preview?.url),
            }))
          : [],
        variants: file?.file?.variants
          ? file.file.variants.map((variant) => ({
              ...variant,
              url: generateUrl(variant?.url),
            }))
          : [],
      },
    };
  }

  function isContributorUser(userId, userEmail) {
    let isContributorUser = false;
    if (!stores.data.collections?.length) {
      return false;
    }
    if (userStatus.product.isBusinessAdmin) {
      return false;
    }
    stores.data.collections.forEach((collection) => {
      if (
        !isContributorUser &&
        collection?.libraryType &&
        collection?.permissions?.contributor_users?.some(
          (obj) => obj?.email === userEmail || obj.user_id === userId,
        )
      ) {
        isContributorUser = true;
      }
    });
    return isContributorUser;
  }
  function isContributorUserInCollection(userId, userEmail, collectionId) {
    let isContributorUser = false;
    if (!stores.data.collections?.length) {
      return false;
    }
    stores.data.collections.forEach((collection) => {
      if (
        !isContributorUser &&
        collection?.libraryType &&
        collection?.permissions?.contributor_users?.some(
          (obj) => obj?.email === userEmail || obj.user_id === userId,
        ) &&
        collection?._id === collectionId
      ) {
        isContributorUser = true;
      }
    });
    return isContributorUser;
  }
  function parseFiles(files) {
    return files?.map(parseFile);
  }

  function parseGroups(groups) {
    return groups?.map((group) => ({
      ...group,
      collections: group.collections || [],
    }));
  }
  function parseCollections(collections) {
    return collections?.map(parseCollection);
  }

  function parseData() {
    const files = getStore('FILES').state;
    getStore('FILES').addOrUpdate(parseFiles(files));
    const collections = getStore('COLLECTIONS').state;
    getStore('COLLECTIONS').addOrUpdate(parseCollections(collections));
    stores.setData([
      {
        type: 'parse',
        store: 'files',
        func: (files) => parseFiles(files),
      },
      {
        type: 'parse',
        store: 'uploaded-files',
        func: (files) => parseFiles(files),
      },
      {
        type: 'parse',
        store: 'groups',
        func: (groups) => parseGroups(groups),
      },
      {
        type: 'parse',
        store: 'collections',
        func: (collections) => parseCollections(collections),
      },
    ]);
  }

  async function saveApprovalStatus(file, newStatus, message) {
    const { id } = User.djangoProfile;
    const saveObject = {
      approval: {
        status: newStatus,
        status_changed_by: id,
        message,
        status_change_reason: 'manual',
      },
    };
    saveObject.share_to = newStatus === 'approved' ? 'not-set' : 'none';
    try {
      if (Array.isArray(file)) {
        const ids = file.map((f) => f._id);
        await saveDocuments(ids, saveObject);
        await removeFiles(ids);
      } else {
        saveObject._id = file._id;
        await saveDocument(saveObject, undefined);
        await removeFiles([saveObject._id]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const track = {
    download: (file) => {
      analytics.trackEvent('File Downloaded', {
        File: file._id,
        Service: type,
        'File Download Type': 'Download',
        'File Import Source': file?.import?.from || 'Uploaded',
        'File Import Reference': file?.import?.reference,
      });
    },
    open: (file) => {
      analytics.trackEvent('File Downloaded', {
        File: file._id,
        Service: type,
        'File Download Type': 'Open',
        'File Import Source': file?.import?.from || 'Uploaded',
        'File Import Reference': file?.import?.reference,
      });
    },
    insert: (file) => {
      analytics.trackEvent('File Downloaded', {
        File: file._id,
        Service: type,
        'File Download Type': 'Insert',
        'File Import Source': file?.import?.from || 'Uploaded',
        'File Import Reference': file?.import?.reference,
      });
    },
  };

  const stats = {
    search: async (params) => {
      const data = await request.stats.search(params);
      return data;
    },
    list: async (params) => {
      const data = await request.stats.list(params);
      if (data?.files?.length) {
        getStore('FILES').addOrUpdate(data.files);
        stores.setData({
          store: 'files',
          type: 'merge',
          key: '_id',
          data: data.files.map((file) => file.data),
        });
      }
      return data;
    },
  };

  const isReady = isInitialized && !isDown;
  const noCollections = isReady && !stores.data.collections?.length;
  const noFiles =
    isReady && !stores.data?.['files-count'] && !stores.data.files?.length;
  const isUploadingFirstFile =
    noFiles &&
    (!!stores.data?.['processing-files']?.length ||
      !!stores.data?.['uploading-files']?.length);

  const scenarios = {
    noCollections,
    noFiles,
    isUploadingFirstFile,
  };

  const values = {
    scenarios,
    isManage,
    isDataLoading,
    isLoadingCollections,
    setIsManage,
    uploadFile,
    uploadFileVersion,
    renameFile,
    deleteFile,
    getFile,
    getFileBlob,
    getClient,
    getDocument,
    importCheckReferences,
    importGetFiles,
    savePartial,
    saveDocument,
    saveDocuments,
    saveDocumentsWhere,
    saveDocumentFeedback,
    saveDocumentFeedbackViewedStatus,
    removeDocumentFeedback,
    reParseFile,
    sasToken,
    createStorageUrl,
    fileIsInState,
    storageTokens,
    setStorageTokens,
    defaultFilters,
    uploadNewFileById,
    data: {
      groups: stores.data.groups || [],
      collections: stores.data.collections || [],
      files: stores.data.files || [],
      'files-count': stores.data['files-count'] || 0,
      ...stores.data,
      folders: (stores.data.folders || []).filter(
        (i) => !!i?.publish?.libraries?.[type] && i?.publish?.is_published,
      ),
    },
    setData: stores.setData,
    stores,
    getFiles,
    deleteDocument,
    moveDocumentToTrash,
    moveDocumentsToTrash,
    recoverDocumentsFromTrash,
    deleteFiles,
    deleteFilesFromState,
    removeFiles,
    isDown,
    reasonDown,
    search,
    getExplore,
    getThumbnailUrl,
    getThumbnail,
    getCollectionThumbnailUrl,
    downloadCollectionAsZip,
    downloadFilesAsZip,
    getFilesAndDownloadAsZip,
    getDownloadUrl,
    finishNewVersion,

    getFilters: async (prefs) => {
      const filters = await request.getFilters(
        {
          settings: prefs.settings,
          allFilters: prefs.allFilters || false,
        },
        prefs.signal,
      );
      if (filters?.uploaded_by?.data?.length) {
        App.getUsersByArrayId(
          filters?.uploaded_by?.data?.map((row) => row.value),
        );
      }
      return filters;
      // return getFilterSplits(true, prefs);
    },
    fileIsPreviewable,

    getToken,

    documentToBase64,
    fileUrlToBase64,
    fileUrlToText,
    documentToText,

    favoriteCollection,
    favoriteDocument,

    isDocumentFavorite,
    isCollectionFavorite,

    getDocumentSharing,
    getUploadedDocument,

    getDownloadBase64,

    importMedia,
    replaceMedia,

    slug,
    setSlug,

    getCollectionsFromArrayId,

    getDocumentFavorites: async () => {
      return getDocumentsFromArrayId(
        User.cloudStorage[type].favorites.documents,
      );
    },
    getCollectionFavorites: async () => {
      return getCollectionsFromArrayId(
        User.cloudStorage[type].favorites.collections,
      );
    },
    getDocumentFavoritesRaw: () => {
      return User.cloudStorage[type].favorites.documents;
    },
    getCollectionFavoritesRaw: () => {
      return User.cloudStorage[type].favorites.collections;
    },

    groups: stores.data?.groups ? stores.data.groups : [],
    saveGroup,
    deleteGroup,
    sortGroups,

    /** COLLECTIONS */
    collections: stores.data?.collections ? stores.data.collections : [],
    saveCollection,
    deleteCollection,
    sortCollections,
    getCollection,
    getAllFilesInCollection,
    collectionIsInGroup,
    getTokens,

    getDocumentsFromArrayId,

    setTriggerUpload,
    triggerUpload,

    fileType,
    filterFileType,
    fileTypesAllowed,
    getUploadFileByChecksum,
    extensionsAllowed,
    type,
    name,
    alreadyUploaded,
    filterFilesAlreadyUploaded,
    stats,

    isContributorUser,
    isContributorUserInCollection,

    getAttributesEstimation,

    setGlobalSettings,
    globalSettings,

    setGlobalFilesHook,
    globalFilesHook,

    setEditingCollection,
    editingCollection,

    getCollections,

    getOCRContextForFile,
    updateOCRContextStatusForFile,

    setEditingCollectionGroup,
    editingCollectionGroup,

    track,

    request,

    isInitialized,

    getUnprocessedFiles,

    saveApprovalStatus,

    filters: [],
    isLoadingFields,
    isFileSupported,

    mainContainerId,

    uploadLabel,

    integrations: props.integrations,
    markets: props.markets,

    attributesEstimation,

    libraryName,
  };

  return values;
}
