import { useEffect, useState } from 'react';
import User from './User';

export default class Community {
  rawData: any;

  permissions: {
    isAdmin: boolean;
    isOwner: boolean;
    isUser: boolean;
  } = {
    isAdmin: false,
    isOwner: false,
    isUser: false,
  };

  async request(path: string, prefs: RequestInit) {
    const res = await fetch(`${window.pickit.config.MEDIA_URL}${path}`, {
      ...prefs,
      headers: {
        ...prefs.headers,
        Authorization: `JWT ${User.token.jwt}`,
        'pickit-community-slug': this.rawData.communitySlug,
      },
    });
    const json = await res.json();
    return json;
  }

  api = {
    getUsers: async () => {
      const res = await this.request('/proxy/community/users', {
        method: 'GET',
      });
      return res;
    },
    removeUser: async (userId: string) => {
      const res = await this.request(
        `/proxy/community/users/remove/${userId}`,
        {
          method: 'DELETE',
        },
      );
      return res;
    },
  };

  async setRawData(data: any) {
    this.rawData = data;
  }

  constructor(data: any) {
    this.rawData = data;
    const cleanGroups =
      data?.groups?.map((group: string) => group.split('_').pop()) || [];
    if (cleanGroups.includes('owner')) {
      this.permissions.isOwner = true;
    }
    if (cleanGroups.includes('creator')) {
      this.permissions.isAdmin = true;
    }
    if (
      cleanGroups.includes('user') &&
      !this.permissions.isAdmin &&
      !this.permissions.isOwner
    ) {
      this.permissions.isUser = true;
    }
  }
}
